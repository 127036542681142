/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScheduledTweets = /* GraphQL */ `
  query GetScheduledTweets($id: ID!) {
    getScheduledTweets(id: $id) {
      id
      userDBID
      userDBNumericID
      userDBRandomID
      tweetDBNumericID
      tweetDBRandomID
      tweetText
      tweetMedia
      tweetMediaURL
      tweetAdditionalData
      scheduledTimeUnix
      scheduledTimeDate
      creationSource
      postAttempted
      postAttemptedError
      postCompleted
      postUserEdited
      postUserDeleted
      validator1
      validator2
      validator3
      validator4
      createdTweetId
      createdAt
      updatedAt
    }
  }
`;
export const listScheduledTweets = /* GraphQL */ `
  query ListScheduledTweets(
    $filter: ModelScheduledTweetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduledTweets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userDBID
        userDBNumericID
        userDBRandomID
        tweetDBNumericID
        tweetDBRandomID
        tweetText
        tweetMedia
        tweetMediaURL
        tweetAdditionalData
        scheduledTimeUnix
        scheduledTimeDate
        creationSource
        postAttempted
        postAttemptedError
        postCompleted
        postUserEdited
        postUserDeleted
        validator1
        validator2
        validator3
        validator4
        createdTweetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTwitterTemporaryAuth = /* GraphQL */ `
  query GetTwitterTemporaryAuth($id: ID!) {
    getTwitterTemporaryAuth(id: $id) {
      id
      amazonUserId
      userId
      userEmailAddress
      timestamp
      oauthURL
      oauthToken
      oauthTokenSecret
      oauthVerifier
      oauthVariable
      createdAt
      updatedAt
    }
  }
`;
export const listTwitterTemporaryAuths = /* GraphQL */ `
  query ListTwitterTemporaryAuths(
    $filter: ModelTwitterTemporaryAuthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTwitterTemporaryAuths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amazonUserId
        userId
        userEmailAddress
        timestamp
        oauthURL
        oauthToken
        oauthTokenSecret
        oauthVerifier
        oauthVariable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestModel = /* GraphQL */ `
  query GetTestModel($id: ID!) {
    getTestModel(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTestModels = /* GraphQL */ `
  query ListTestModels(
    $filter: ModelTestModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserTwitterAuth = /* GraphQL */ `
  query GetUserTwitterAuth($id: ID!) {
    getUserTwitterAuth(id: $id) {
      id
      userId
      emailAddress
      twitterAuthToken
      twitterRefreshToke
      twitterUsername
      createdAt
      updatedAt
    }
  }
`;
export const listUserTwitterAuths = /* GraphQL */ `
  query ListUserTwitterAuths(
    $filter: ModelUserTwitterAuthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTwitterAuths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        emailAddress
        twitterAuthToken
        twitterRefreshToke
        twitterUsername
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAccounts = /* GraphQL */ `
  query GetUserAccounts($id: ID!) {
    getUserAccounts(id: $id) {
      id
      numeric_id
      random_id
      accountEmail
      paid_ever
      paid_active
      subscription_type
      signup_time
      signup_date
      signup_source
      attributes_email
      attributes_email_verified
      attributes_sub
      clientEndpoint
      keyPrefix
      cognitoClientId
      cognitoUserPoolId
      cognitoUserDataKey
      cognitoUsername
      twitterEmail
      twitterUsername
      twitterAccessToken
      twitterSecretAccessToken
      twitterDateAuthorized
      autopilotmode
      entityname
      entitydescription
      entitytopics
      entitywebsite
      preferenceHashtags
      preferenceFrequency
      preferenceLength
      preferenceEmojis
      preferenceTone
      preferenceGoals
      twitterTokenSecure
      twitterTokenSecretSecure
      productEmailSubscription
      createdAt
      updatedAt
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        numeric_id
        random_id
        accountEmail
        paid_ever
        paid_active
        subscription_type
        signup_time
        signup_date
        signup_source
        attributes_email
        attributes_email_verified
        attributes_sub
        clientEndpoint
        keyPrefix
        cognitoClientId
        cognitoUserPoolId
        cognitoUserDataKey
        cognitoUsername
        twitterEmail
        twitterUsername
        twitterAccessToken
        twitterSecretAccessToken
        twitterDateAuthorized
        autopilotmode
        entityname
        entitydescription
        entitytopics
        entitywebsite
        preferenceHashtags
        preferenceFrequency
        preferenceLength
        preferenceEmojis
        preferenceTone
        preferenceGoals
        twitterTokenSecure
        twitterTokenSecretSecure
        productEmailSubscription
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommand = /* GraphQL */ `
  query GetCommand($id: ID!) {
    getCommand(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listCommands = /* GraphQL */ `
  query ListCommands(
    $filter: ModelCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
