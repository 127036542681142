/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScheduledTweets = /* GraphQL */ `
  mutation CreateScheduledTweets(
    $input: CreateScheduledTweetsInput!
    $condition: ModelScheduledTweetsConditionInput
  ) {
    createScheduledTweets(input: $input, condition: $condition) {
      id
      userDBID
      userDBNumericID
      userDBRandomID
      tweetDBNumericID
      tweetDBRandomID
      tweetText
      tweetMedia
      tweetMediaURL
      tweetAdditionalData
      scheduledTimeUnix
      scheduledTimeDate
      creationSource
      postAttempted
      postAttemptedError
      postCompleted
      postUserEdited
      postUserDeleted
      validator1
      validator2
      validator3
      validator4
      createdTweetId
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduledTweets = /* GraphQL */ `
  mutation UpdateScheduledTweets(
    $input: UpdateScheduledTweetsInput!
    $condition: ModelScheduledTweetsConditionInput
  ) {
    updateScheduledTweets(input: $input, condition: $condition) {
      id
      userDBID
      userDBNumericID
      userDBRandomID
      tweetDBNumericID
      tweetDBRandomID
      tweetText
      tweetMedia
      tweetMediaURL
      tweetAdditionalData
      scheduledTimeUnix
      scheduledTimeDate
      creationSource
      postAttempted
      postAttemptedError
      postCompleted
      postUserEdited
      postUserDeleted
      validator1
      validator2
      validator3
      validator4
      createdTweetId
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduledTweets = /* GraphQL */ `
  mutation DeleteScheduledTweets(
    $input: DeleteScheduledTweetsInput!
    $condition: ModelScheduledTweetsConditionInput
  ) {
    deleteScheduledTweets(input: $input, condition: $condition) {
      id
      userDBID
      userDBNumericID
      userDBRandomID
      tweetDBNumericID
      tweetDBRandomID
      tweetText
      tweetMedia
      tweetMediaURL
      tweetAdditionalData
      scheduledTimeUnix
      scheduledTimeDate
      creationSource
      postAttempted
      postAttemptedError
      postCompleted
      postUserEdited
      postUserDeleted
      validator1
      validator2
      validator3
      validator4
      createdTweetId
      createdAt
      updatedAt
    }
  }
`;
export const createTwitterTemporaryAuth = /* GraphQL */ `
  mutation CreateTwitterTemporaryAuth(
    $input: CreateTwitterTemporaryAuthInput!
    $condition: ModelTwitterTemporaryAuthConditionInput
  ) {
    createTwitterTemporaryAuth(input: $input, condition: $condition) {
      id
      amazonUserId
      userId
      userEmailAddress
      timestamp
      oauthURL
      oauthToken
      oauthTokenSecret
      oauthVerifier
      oauthVariable
      createdAt
      updatedAt
    }
  }
`;
export const updateTwitterTemporaryAuth = /* GraphQL */ `
  mutation UpdateTwitterTemporaryAuth(
    $input: UpdateTwitterTemporaryAuthInput!
    $condition: ModelTwitterTemporaryAuthConditionInput
  ) {
    updateTwitterTemporaryAuth(input: $input, condition: $condition) {
      id
      amazonUserId
      userId
      userEmailAddress
      timestamp
      oauthURL
      oauthToken
      oauthTokenSecret
      oauthVerifier
      oauthVariable
      createdAt
      updatedAt
    }
  }
`;
export const deleteTwitterTemporaryAuth = /* GraphQL */ `
  mutation DeleteTwitterTemporaryAuth(
    $input: DeleteTwitterTemporaryAuthInput!
    $condition: ModelTwitterTemporaryAuthConditionInput
  ) {
    deleteTwitterTemporaryAuth(input: $input, condition: $condition) {
      id
      amazonUserId
      userId
      userEmailAddress
      timestamp
      oauthURL
      oauthToken
      oauthTokenSecret
      oauthVerifier
      oauthVariable
      createdAt
      updatedAt
    }
  }
`;
export const createTestModel = /* GraphQL */ `
  mutation CreateTestModel(
    $input: CreateTestModelInput!
    $condition: ModelTestModelConditionInput
  ) {
    createTestModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTestModel = /* GraphQL */ `
  mutation UpdateTestModel(
    $input: UpdateTestModelInput!
    $condition: ModelTestModelConditionInput
  ) {
    updateTestModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestModel = /* GraphQL */ `
  mutation DeleteTestModel(
    $input: DeleteTestModelInput!
    $condition: ModelTestModelConditionInput
  ) {
    deleteTestModel(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createUserTwitterAuth = /* GraphQL */ `
  mutation CreateUserTwitterAuth(
    $input: CreateUserTwitterAuthInput!
    $condition: ModelUserTwitterAuthConditionInput
  ) {
    createUserTwitterAuth(input: $input, condition: $condition) {
      id
      userId
      emailAddress
      twitterAuthToken
      twitterRefreshToke
      twitterUsername
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTwitterAuth = /* GraphQL */ `
  mutation UpdateUserTwitterAuth(
    $input: UpdateUserTwitterAuthInput!
    $condition: ModelUserTwitterAuthConditionInput
  ) {
    updateUserTwitterAuth(input: $input, condition: $condition) {
      id
      userId
      emailAddress
      twitterAuthToken
      twitterRefreshToke
      twitterUsername
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTwitterAuth = /* GraphQL */ `
  mutation DeleteUserTwitterAuth(
    $input: DeleteUserTwitterAuthInput!
    $condition: ModelUserTwitterAuthConditionInput
  ) {
    deleteUserTwitterAuth(input: $input, condition: $condition) {
      id
      userId
      emailAddress
      twitterAuthToken
      twitterRefreshToke
      twitterUsername
      createdAt
      updatedAt
    }
  }
`;
export const createUserAccounts = /* GraphQL */ `
  mutation CreateUserAccounts(
    $input: CreateUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    createUserAccounts(input: $input, condition: $condition) {
      id
      numeric_id
      random_id
      accountEmail
      paid_ever
      paid_active
      subscription_type
      signup_time
      signup_date
      signup_source
      attributes_email
      attributes_email_verified
      attributes_sub
      clientEndpoint
      keyPrefix
      cognitoClientId
      cognitoUserPoolId
      cognitoUserDataKey
      cognitoUsername
      twitterEmail
      twitterUsername
      twitterAccessToken
      twitterSecretAccessToken
      twitterDateAuthorized
      autopilotmode
      entityname
      entitydescription
      entitytopics
      entitywebsite
      preferenceHashtags
      preferenceFrequency
      preferenceLength
      preferenceEmojis
      preferenceTone
      preferenceGoals
      twitterTokenSecure
      twitterTokenSecretSecure
      productEmailSubscription
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAccounts = /* GraphQL */ `
  mutation UpdateUserAccounts(
    $input: UpdateUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    updateUserAccounts(input: $input, condition: $condition) {
      id
      numeric_id
      random_id
      accountEmail
      paid_ever
      paid_active
      subscription_type
      signup_time
      signup_date
      signup_source
      attributes_email
      attributes_email_verified
      attributes_sub
      clientEndpoint
      keyPrefix
      cognitoClientId
      cognitoUserPoolId
      cognitoUserDataKey
      cognitoUsername
      twitterEmail
      twitterUsername
      twitterAccessToken
      twitterSecretAccessToken
      twitterDateAuthorized
      autopilotmode
      entityname
      entitydescription
      entitytopics
      entitywebsite
      preferenceHashtags
      preferenceFrequency
      preferenceLength
      preferenceEmojis
      preferenceTone
      preferenceGoals
      twitterTokenSecure
      twitterTokenSecretSecure
      productEmailSubscription
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserAccounts = /* GraphQL */ `
  mutation DeleteUserAccounts(
    $input: DeleteUserAccountsInput!
    $condition: ModelUserAccountsConditionInput
  ) {
    deleteUserAccounts(input: $input, condition: $condition) {
      id
      numeric_id
      random_id
      accountEmail
      paid_ever
      paid_active
      subscription_type
      signup_time
      signup_date
      signup_source
      attributes_email
      attributes_email_verified
      attributes_sub
      clientEndpoint
      keyPrefix
      cognitoClientId
      cognitoUserPoolId
      cognitoUserDataKey
      cognitoUsername
      twitterEmail
      twitterUsername
      twitterAccessToken
      twitterSecretAccessToken
      twitterDateAuthorized
      autopilotmode
      entityname
      entitydescription
      entitytopics
      entitywebsite
      preferenceHashtags
      preferenceFrequency
      preferenceLength
      preferenceEmojis
      preferenceTone
      preferenceGoals
      twitterTokenSecure
      twitterTokenSecretSecure
      productEmailSubscription
      createdAt
      updatedAt
    }
  }
`;
export const createCommand = /* GraphQL */ `
  mutation CreateCommand(
    $input: CreateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    createCommand(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand(
    $input: UpdateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    updateCommand(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommand = /* GraphQL */ `
  mutation DeleteCommand(
    $input: DeleteCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    deleteCommand(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
