import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import twitterlogo from './media/twitterlogo.png';
import loader from './media/Infinity-1s-200px.gif';
import { Auth } from 'aws-amplify';
import { updateUserAccounts, updateScheduledTweets } from './graphql/mutations';
import { listScheduledTweets } from "./graphql/queries";
import {
  Button,
  //Flex,
  Heading,
  //Text,
  //TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";

import axios from "axios";
import {makeClientPostRequest} from './MakeRequest';
import {fetchCurrentDBUserOnLoad, addUser, fetchUsers, generateRandomNumber, generateRandomAlphanumeric} from './DataHelper'
import { API, graphqlOperation } from "aws-amplify";
//Autopilot todo - editing a user (like with the edittwitter thing)
import { createScheduledTweets } from './graphql/mutations';
//import flatpickr from "flatpickr";
//https://github.com/flatpickr/flatpickr
//https://flatpickr.js.org/examples/
//TODO known UX issue is getting date to show in the input field  - spent 3h+ and couldn't get it to work on pickr
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import stripe from 'stripe';

const globals_aws_app_api_systemapi_base = 'https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main';

const stripeSecretKey = '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';

const stripeInstance = stripe(stripeSecretKey);
//const stripe = require('stripe')('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');

//https://postmatica.com/payment-success
//https://postmatica.com/payment-cancellation
//price_1NLH3hDSXeKFkbbLVisVYlpu
//price_1NLH3hDSXeKFkbbLVisVYlpu
//prod_O7W5TpEAcIKr4S


var authDataURL = "";
var authDataOauthToken = "";
var authDataOauthTokenSecret = "";

// Function to handle sign-out
const handleSignOut = async () => {
  try {
    // Perform any clean-up tasks or custom logic here

    // Sign out the user
    await Auth.signOut();

    // Additional custom logic after sign-out

    // Redirect or update the UI as needed
    // Example:
    window.location.href = '/signup'; // Redirect to the login page
  } catch (error) {
    console.log('Error signing out:', error);
  }
};
//Replaced <Button onClick={signOut}>Sign Out</Button> so I could customize it


//Will need to pull from DB instead eventually b/c may not be stored locally and security
var user_email = localStorage.getItem("local_user_email");
if(user_email===null)
{
  user_email = localStorage.getItem("accountEmail");
}
//BUG - still not working in some instances 6-14-23 in staging test
var user_twitter_username = localStorage.getItem("auth_user_screen_name");
if(user_twitter_username===null || user_twitter_username==='null'){
  user_twitter_username = localStorage.getItem("twitterUsername");
};

async function defineTwitterData(){
  authDataURL = localStorage.getItem("auth_url");
  authDataOauthToken = localStorage.getItem("oauth_token");    
  authDataOauthTokenSecret = localStorage.getItem("oauth_token_secret");
}

async function fetchAuthUrl(url) {
  try {
    const response = await axios.get(url);
    if(response){
    //console.log(response);
    localStorage.setItem("auth_url", response.data.authLink.url);
    localStorage.setItem("oauth_token", response.data.authLink.oauth_token);
    localStorage.setItem("oauth_token_secret", response.data.authLink.oauth_token_secret);
    authDataURL = localStorage.getItem("auth_url");
    authDataOauthToken = localStorage.getItem("oauth_token");    
    authDataOauthTokenSecret = localStorage.getItem("oauth_token_secret");
    document.getElementById('twitterAuthStartButton').setAttribute('href',authDataURL);
    document.getElementById('twitterAuthStartButton2').setAttribute('href',authDataURL);
    defineTwitterData();
    }
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data:', error);
    throw error;
  }
}  

var auth_user_oauth_token;
var auth_user_oauth_token_secret;
var auth_user_oauth_verifier;
var newuserparam;

function checkIfAuth(){
  //7-2-23 change; NEEDS REVIEW
  if (localStorage.getItem("auth_user_state") === 'true') {
    //document.getElementById('twitterAuthStartButton').hidden = true;

    auth_user_oauth_token = localStorage.getItem("auth_user_oauth_token");
    auth_user_oauth_token_secret = localStorage.getItem("auth_user_oauth_token");
    //console.log(localStorage.getItem("twitterUsername"));
    //const twitterUsername = document.getElementById('twitterNameURL');

    document.getElementById('accountWrapperEmail').innerHTML = ("Email: "+user_email);
    
    document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+user_twitter_username);    
    const twitterNameURL = document.getElementById('twitterNameURL');
    
    //const user_twitter_username = localStorage.getItem('auth_user_screen_name');

  // Set the href value
  twitterNameURL.href = `https://twitter.com/${user_twitter_username}`;
    newuserparam='false';
  }
}

async function retryFunction(fn, maxRetries) {
  let retryCount = 0;
  
  while (retryCount < maxRetries) {
    try {
      const result = await fn();
      return result;
    } catch (error) {
      console.error("An error occurred:", error);
      // Retry the function after a short delay
      retryCount++;
      console.log(`Retry attempt ${retryCount} after encountering an error`);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust the delay as needed
    }
  }
  
  throw new Error(`Maximum retry attempts (${maxRetries}) reached`);
}

//6-14-1:16pm this works but sometimes hits a 502 so you need to catch the error and retry before failing
async function generateAIcontent(){
var loadingelement = document.getElementById('newTweetsLoader');
loadingelement.style.display = 'block';

var emojiElement = document.getElementById('generatedTweetPreferenceSelectionEmojis');
var hashtagsElement = document.getElementById('generatedTweetPreferenceSelectionHashtags');
var capsElement = document.getElementById('generatedTweetPreferenceSelectionCaps');

var emojiElementValue = emojiElement.checked.toString();
var hashtagsElementValue = hashtagsElement.checked.toString();
var capsElementValue = capsElement.checked.toString();
  
//console.log(emojiElementValue + hashtagsElementValue + capsElementValue);

//console.log('Start: Generating tweets...');
const topicsInput = document.getElementById("topics").value;
const topicsInputFormatted = topicsInput;

var responseObject;
try {
  //const response = await axios.post('https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main/systemapi/generatecontent', {
  //AWS_APP_API_SYSTEMAPI_BASE_URL
  //const response = await axios.post('https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/generatecontent', {
  //NEW: 7-1-23 added .env for execute variables
  const response = await axios.post(globals_aws_app_api_systemapi_base+'/systemapi/generatecontent', {
    topics: topicsInputFormatted, 
    preferences: {
      emoji: emojiElementValue,
      hashtags: hashtagsElementValue,
      caps: capsElementValue
    }
  });
  responseObject = response.data.response;
  console.log(responseObject);
  //console.log('Success: Tweets have been generated!');
  var loadingelement = document.getElementById('newTweetsLoader');
  loadingelement.style.display = 'none';
} catch (error) {
  console.error("An error occurred:", error);
  console.log('Failure: Tweets could not be posted.');
}

//Remove numbers; ideally this happens in the prompt but stopgap for now
//TODO this makes it impossible to use numbers like "1. and 2." in your AI generated tweets so keep that in mind
//TODO 6-16 added this bc of console error about doing replace on something that didn't exist but may not work / null might be wrong val to check for
//if(responseObject!==null){
var updatedString = responseObject.replace(/^\d+\.\s*/gm, "").replace(/"/g, "");
//6-29 added below to remove the " " pattern in some of the tweets very spammy
//updatedString = updatedString.replace(/" "/g, "");
var arr = updatedString.split("-end-");
//6-30 Filter out generated tweets <5 characters...need to do for the autogenerated ones too not just the table of generated ones
arr = arr.filter(function(item) {
  return item.length >= 50;
});

//}

//Keep! Very important for the saveTweet functionality to keep working
async function saveTweet(tweetText, inputDate, inputDateUnix) {
  var thistweeteruserid = localStorage.getItem("this_user_id"); 
  var thistweeternumericid = localStorage.getItem("numeric_id"); //numeric_id;
  var thistweeterrandomid = localStorage.getItem("random_id"); //random_id;
  //var thistweeteraccesstoken = localStorage.getItem("twitterAccessToken"); //random_id;  
  var thistweetersecretaccesstoken = localStorage.getItem("twitterSecretAccessToken"); //random_id;  
  var thistweetertokensecure = localStorage.getItem("twitterTokenSecure"); //random_id;  
  var thistweetertokensecretsecure = localStorage.getItem("twitterTokenSecretSecure"); //random_id;  
  var tweetrandomid = generateRandomNumber();
  var tweetrandomalphanumericid = generateRandomAlphanumeric();
 
  try {
    const newScheduledTweets = await API.graphql({
    query: createScheduledTweets,
    variables: {
    input: {
    "userDBID": thistweeteruserid,
    "userDBNumericID": thistweeternumericid,
    "userDBRandomID": thistweeterrandomid,
    "tweetDBNumericID": tweetrandomid,
    "tweetDBRandomID": tweetrandomalphanumericid,
    "tweetText": tweetText,
    "tweetMedia": "null",
    "tweetMediaURL": "null",
    "tweetAdditionalData": "null",
    "scheduledTimeUnix": inputDateUnix,
    "scheduledTimeDate": inputDate,
    "creationSource": "user",
    "postAttempted": "null",
    "postAttemptedError": "null",
    "postCompleted": "false",
    "postUserEdited": "null",
    "postUserDeleted": "false",
    "validator3": thistweetertokensecure,
    "validator4": thistweetertokensecretsecure,
    "createdTweetId": "null"
    }
    }
    });
    
    } catch (error) {
    console.error("Error creating new scheduled tweets:", error);
    }


  const successAlert = document.getElementById('successAlert');
  successAlert.style.display = 'block';

  setTimeout(() => {
  successAlert.style.display = 'none';
  }, 3000);
  
}
window.saveTweet = saveTweet;

const handlePostClickGenerated = (tweetText) => {
  const twitterAccessTokenSecure = localStorage.getItem("twitterTokenSecure");
  const twitterSecretAccessTokenSecure = localStorage.getItem("twitterTokenSecretSecure");

  if (twitterAccessTokenSecure !== '' && twitterSecretAccessTokenSecure !== '') {
    try {
      makeClientPostRequest(tweetText, twitterAccessTokenSecure, twitterSecretAccessTokenSecure);
      const tweetNowSuccessAlert = document.getElementById('tweetNowSuccessAlert');
      
      tweetNowSuccessAlert.style.display = 'block';
    
      setTimeout(() => {
        tweetNowSuccessAlert.style.display = 'none';
      }, 3000);
      
      //console.log('Tweet posted.')
    } catch (error) {
      console.error(error);
    }
  }
};

document.getElementById("newTweetsLoader").hidden = true;
//document.getElementById("boostLoader").hidden = true;
const tableContainer = document.getElementById("generatedTweetsTableContainer");

tableContainer.innerHTML += `<table class="generatedTweetsTable"><thead><tr><th>Tweet</th><th>Date</th><th>Save</th><th>Ship It</th></tr></thead><tbody>`;

arr.forEach((item, index) => {
  
  const uniqueId = `AItweetTableId-${index}`;
  const placeholder = '  \u{1F4C5}  '; // Adds space on both sides of the emoji

  const row = document.createElement("tr");

  // Create and append the item cell
  const itemCell = document.createElement("td");
  itemCell.textContent = item;
  itemCell.classList.add("custom-cell");
  row.appendChild(itemCell);

  // Create and append the date cell
  const dateCell = document.createElement("td");
  dateCell.classList.add("custom-cell");
  dateCell.classList.add("centeredTDs");
  dateCell.classList.add("tableEmojis");
  
  const input = document.createElement("input");
  input.setAttribute("type", "text");
  input.setAttribute("id", uniqueId);
  input.setAttribute('placeholder', placeholder);
  input.setAttribute('size', '3');
  input.setAttribute('maxlength', '3');
  input.classList.add("date-input");
  dateCell.appendChild(input);  
  
  //flatpickrInit(dateCell); // Initialize the Flatpickr date picker for the date cell
  const fp = flatpickrInit(dateCell); // Initialize the Flatpickr date picker for the date cell and store the instance

  row.appendChild(dateCell);

  const saveCell = document.createElement("td");
  const saveSpan = document.createElement("span");
  saveSpan.textContent = "Save";
  saveSpan.classList.add("custom-cell");
  saveSpan.classList.add("centeredTDs");

  var scheduledUnixTime;
  if(scheduledUnixTime===null){
    scheduledUnixTime='1686801879';
  }

  saveSpan.addEventListener("click", function() {
    //const selectedDate = dateCell.querySelector("input").value; // Get the value of the selected date
    var selectedDate = fp.selectedDates[0]; // Get the selected date from the Flatpickr instance
  if (!selectedDate) {
    selectedDate = '000'; // Set a default value if no date is selected
  }
    const selectedDateUnix = convertFlatpickrToUnixTime(selectedDate);
    //console.log(selectedDate);
    saveTweet(item, selectedDate, selectedDateUnix);
  });

  saveCell.appendChild(saveSpan);
  row.appendChild(saveCell);

  const tweetNowCell = document.createElement("td");
  const tweetNowSpan = document.createElement("span");
  tweetNowSpan.innerHTML = "&#x1F4DD;";
  tweetNowSpan.classList.add("custom-cell");
  tweetNowSpan.classList.add("centeredTDs");
  tweetNowSpan.classList.add("tableEmojis");
  tweetNowCell.appendChild(tweetNowSpan);
  row.appendChild(tweetNowCell);

  tweetNowSpan.addEventListener("click", function() {
    handlePostClickGenerated(itemCell.textContent);
  });

  // Append the row to the table body
  tableContainer.querySelector("tbody").appendChild(row);
});


};

//TODO if responseobject is undefined so will not show up in the console.log response/may break
async function generateAIcontentretries(){
  retryFunction(generateAIcontent, 3)
    .then(responseObject => {
      //console.log("Function succeeded:", responseObject);
    })
    .catch(error => {
      //console.error("Function failed after retries:", error);
    });
  }

  function flatpickrInit(element) {
    if (element) {
      const fp = flatpickr(element, {
        // Configuration options for Flatpickr
        dateFormat: 'Y-m-d',
        minDate: 'today',
        enableTime: true,       
        //allowInput: false 
      });
      return fp;
    }
  }

  function convertFlatpickrToUnixTime(timeValue) {
    const unixTime = Date.parse(timeValue) / 1000; // Divide by 1000 to convert from milliseconds to seconds
    return unixTime;
  }


  async function cronpost() {
    try {
      //const response = await axios.post('https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/cronpost', {});
      //NEW - env for AWS variables
      //process.env.AWS_APP_API_SYSTEMAPI_BASE_URL+
      //const response = await axios.post('https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/clientrequesttest', {});
      const response = await axios.post(globals_aws_app_api_systemapi_base+'/systemapi/clientrequesttest', {});
      //console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  }  

  async function testemail() {
    try {
      const response = await axios.post(globals_aws_app_api_systemapi_base+'/systemapi/testemail', {});
      console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  }  

  async function testdailyemail() {
    try {
      const response = await axios.post(globals_aws_app_api_systemapi_base+'/systemapi/dailyemail', {});
      console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      throw error;
    }
  }  


  async function deleteThisTweet(thisvariable, tweet_id, tweetDBNumericID, tweetDBRandomID){
    //console.log("ID: "+tweet_id+" Numeric ID: "+tweetDBNumericID+" Random ID: "+tweetDBRandomID);
    try {
      const input = {
        id: tweet_id,
        postUserDeleted: "true"
      };
  
      const condition = {
        tweetDBNumericID: { eq: tweetDBNumericID },
        tweetDBRandomID: { eq: tweetDBRandomID }
      };
  
      const updatedScheduledTweet = await API.graphql(
        graphqlOperation(updateScheduledTweets, { input, condition })
      );
  
      console.log('Scheduled tweet updated:', updatedScheduledTweet.data.updateScheduledTweets);
      
      const deleteTweetSuccessAlert = document.getElementById('deleteTweetSuccessAlert');
      
      deleteTweetSuccessAlert.style.display = 'block';
    
      setTimeout(() => {
        deleteTweetSuccessAlert.style.display = 'none';
      }, 3000);

    } catch (error) {
      console.error('Error updating scheduled tweet:', error);
    }    
    
  }
  
  window.deleteThisTweet = deleteThisTweet;

  async function fetchYourScheduledTweets(usernumericid){
  /* 6-20-23 - fix in. The graphql query stopped working right b/c I wasn't including pagination; now fixed and should match dynamodb.
    const yourScheduledTweets = await API.graphql({
    query: listScheduledTweets,
    variables: {  filter: {
      userDBNumericID: { eq: usernumericid }
    } }
  });  
  */

  if(usernumericid!='null' && usernumericid!=null){
  let nextToken = null; // Initialize nextToken as null

  const storedscheduledTweets = []; // Initialize an array to store all the scheduled tweets

  do {
    const yourScheduledTweets = await API.graphql({
      query: listScheduledTweets,
      variables: {
        filter: {
          userDBNumericID: { eq: usernumericid },
          postUserDeleted: { ne: true } // Filter condition: postUserDeleted not equal to true
        },
        nextToken: nextToken // Pass the nextToken value in the variables
      }
    });

    const items = yourScheduledTweets.data.listScheduledTweets.items;
    storedscheduledTweets.push(...items); // Append the retrieved items to the scheduledTweets array

    nextToken = yourScheduledTweets.data.listScheduledTweets.nextToken; // Update nextToken with the value from the response

  } while (nextToken); // Repeat the loop until nextToken is null

  //console.log(storedscheduledTweets);

  //const scheduledTweets = yourScheduledTweets.data.listScheduledTweets.items;
  //console.log(scheduledTweets);

  let tableHTML = "<table id='userTweetsTable' style={{ fontFamily: 'Inter', sans-serif }}><tr><th>Tweet Text</th><th>Created At</th><th>Scheduled Time</th><th>Valid</th><th>Delete</th><th>Status</th></tr>";

  let sortedScheduledTweets = storedscheduledTweets.sort((a, b) => a.scheduledTimeUnix - b.scheduledTimeUnix);

  let countAlreadyPosted = 0; // Counter variable for statuses with scheduled time less than current time

  sortedScheduledTweets.forEach(tweet => {
    //6-30 added logic to check if deleted/don't show if so (won't tweet it based on index.js and systemcron)
    if(tweet.postUserDeleted!='true'){
    const tweetText = tweet.tweetText;
    const createdAt = new Date(tweet.createdAt).toLocaleString();
    const scheduledTimeUnix = tweet.scheduledTimeUnix;

    const tweet_id = tweet.id;
    const tweetDBNumericID = tweet.tweetDBNumericID;
    const tweetDBRandomID = tweet.tweetDBRandomID;

    //console.log(tweet);

    //6-30 added to check credentials
    const validator3 = tweet.validator3;
    const validator4 = tweet.validator4;
    //console.log(validator3);
    if(validator3!=null && validator4!=null && validator3!='null' && validator4!='null'){
    var credentialsValid = 'true';
    var credentialsValidHTML = '&#x2714;&#xFE0E;';  
  }else{
    var credentialsValid = 'false';
    var credentialsValidHTML = '&#x274C;&#xFE0E;';
    }

    //var deleteEmojiHTML = "<span onClick={deleteThisTweet}>&#128078;</span>";
    const currentTimeUnix = Math.floor(Date.now() / 1000);

const date = new Date(scheduledTimeUnix * 1000); // Multiply by 1000 to convert from seconds to milliseconds

const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to the month since it's zero-based
const day = date.getDate().toString().padStart(2, '0');
const year = date.getFullYear();

const hours = date.getHours();
const minutes = date.getMinutes().toString().padStart(2, '0');
const seconds = date.getSeconds().toString().padStart(2, '0');
const meridiem = hours >= 12 ? 'PM' : 'AM';

const formattedDate = `${month}/${day}/${year}`;
const formattedTime = `${hours % 12}:${minutes}:${seconds} ${meridiem}`;
const dateForTable = formattedDate+" "+formattedTime;

    const scheduledTime = new Date(tweet.scheduledTimeDate).toLocaleString();
    //const status = new Date(tweet.scheduledTimeUnix) < new Date() ? '&#10004;' : '&#128336;';
    const status = tweet.scheduledTimeUnix < currentTimeUnix ? '&#10004;' : '&#128336;';
    //console.log(tweet);

    // Increment count if status is less than current time
  //if (new Date(tweet.scheduledTimeDate) < new Date()) {
    if (tweet.scheduledTimeUnix < currentTimeUnix) {
    countAlreadyPosted++;
  }



    //tableHTML += `<tr><td>${tweetText}</td><td>${createdAt}</td><td>${dateForTable}</td><td class='centeredTDsscheduled'>${credentialsValidHTML}</td><td class='centeredTDsscheduled centeredTDsscheduledcustom'><span onClick=${deleteThisTweet}>&#128078;</span></td><td class='centeredTDsscheduled'>${status}</td></tr>`;
    //tableHTML += `<tr><td>${tweetText}</td><td>${createdAt}</td><td>${dateForTable}</td><td class='centeredTDsscheduled'>${credentialsValidHTML}</td><td class='centeredTDsscheduled centeredTDsscheduledcustom'><span onClick={alert('hi')}>&#128078;</span></td><td class='centeredTDsscheduled'>${status}</td></tr>`;
    tableHTML += `<tr><td>${tweetText}</td><td>${createdAt}</td><td>${dateForTable}</td><td class='centeredTDsscheduled'>${credentialsValidHTML}</td><td class='centeredTDsscheduled centeredTDsscheduledcustom'><span class='deleteButtons' onclick="deleteThisTweet(this,'${tweet_id}','${tweetDBNumericID}', '${tweetDBRandomID}')">&#128078;</span></td><td class='centeredTDsscheduled'>${status}</td></tr>`;
  
  }
  });

  tableHTML += '</table>';

  const tableContainer = document.getElementById('scheduledTweetsTableContainer');
  if(tableHTML!=null && tableContainer!=null){
  tableContainer.innerHTML = tableHTML;
  tableContainer.style.fontFamily = "'Inter', sans-serif";

// Update stats box
const box1NumberElement = document.getElementById('box1-number');
const totalScheduledTweets = storedscheduledTweets.length;

box1NumberElement.textContent = totalScheduledTweets.toString();

const box2Number = document.getElementById('box2-number');
box2Number.textContent = countAlreadyPosted.toString();

const box3Number = document.getElementById('box3-number');
const upcomingTweetsCount = totalScheduledTweets-countAlreadyPosted;
box3Number.textContent = upcomingTweetsCount.toString();
  }
  }
}


async function handleAutopilotToggleChange() {
  var toggle = document.getElementById("autopilotToggle");
  var value = toggle.checked;
  localStorage.setItem("autopilotEnabled", value);
  sessionStorage.setItem("autopilotEnabled", value);
  var user_email = localStorage.getItem("accountEmail");
  var user_id = localStorage.getItem("this_user_id");
  //localStorage.getItem("local_user_email")
  
  // Handle the value change here
  //console.log("Toggle value:", value);

  try {
    const input = {
      id: user_id,
      accountEmail: user_email,
      autopilotmode: value
    };

    const updatedUser = await API.graphql(graphqlOperation(updateUserAccounts, { input }));

    //console.log('User record updated:', updatedUser.data.updateUserAccounts);
  } catch (error) {
    console.error('Error updating user record:', error);
  }
}

async function handleEmailToggleChange() {
  var toggle = document.getElementById("productEmailToggle");
  var value = toggle.checked.toString();
  console.log(value);
  localStorage.setItem("productEmailSubscription", value);
  sessionStorage.setItem("productEmailSubscription", value);
  var user_email = localStorage.getItem("accountEmail");
  var user_id = localStorage.getItem("this_user_id");

  try {
    const input = {
      id: user_id,
      accountEmail: user_email,
      productEmailSubscription: value
    };

    const updatedUser = await API.graphql(graphqlOperation(updateUserAccounts, { input }));
    console.log('User record updated:', updatedUser.data.updateUserAccounts);
  } catch (error) {
    console.error('Error updating user record:', error);
  }
}

async function updateBusinessProfile() {
  var user_email = localStorage.getItem("accountEmail");
  var user_id = localStorage.getItem("this_user_id");
  //localStorage.getItem("local_user_email")
  
  // Handle the value change here
  //console.log("Toggle value:", value);

  // Retrieve the form inputs
  var businessName = document.getElementById("businessName").value;
  var businessDescription = document.getElementById("businessDescription").value;
  var businessWebsite = document.getElementById("businessWebsite").value;
  var businessTopics = document.getElementById("businessTopics").value;
  var businessGoal = document.getElementById("businessGoal").value;

  try {
    const input = {
      id: user_id,
      accountEmail: user_email,
      entityname: businessName,
      entitydescription: businessDescription,
      entitywebsite: businessWebsite,
      entitytopics: businessTopics,
      preferenceGoals: businessGoal
    };

    const updatedUser = await API.graphql(graphqlOperation(updateUserAccounts, { input }));

    const successAlert = document.getElementById('profilesuccessAlert');
    successAlert.style.display = 'block';
  
    setTimeout(() => {
    successAlert.style.display = 'none';
    }, 3000);

    //fetchCurrentDBUserOnLoad(user_email);
    fetchCurrentDBUserOnLoad(user_email)
  .then(() => {
    //console.log(localStorage.getItem("twitterUsername"));
    //forceUIupdates();
    //if (localStorage.getItem("auth_user_state") === 'true') {
      /*
  if(document.getElementById('accountWrapperTwitterName')!=null){
    document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+localStorage.getItem("twitterUsername"))  
    }
    //}

    if(localStorage.getItem("numeric_id")!="null" || localStorage.getItem("numeric_id")!=null){
    fetchYourScheduledTweets(localStorage.getItem("numeric_id"));
    }
    */
  });


    //console.log('User record updated:', updatedUser.data.updateUserAccounts);


  } catch (error) {
    console.error('Error updating user record:', error);
  }
}




  /*
  export async function editUserWithTwitterTokens(user_id, user_email, ){
    
    var tempTwitterUsername = screen_name;
    if(tempTwitterUsername==null){
        tempTwitterUsername = localStorage.getItem("twitter_username");
    }
    if(tempTwitterUsername==null){
        tempTwitterUsername = 'unknown';
    }

    try {
      const input = {
        id: user_id,
        accountEmail: user_email,
        twitterAccessToken: token,
        twitterSecretAccessToken: secret,
        twitterUsername: tempTwitterUsername
      };
  
      const updatedUser = await API.graphql(graphqlOperation(updateUserAccounts, { input }));
  
      console.log('User record updated:', updatedUser.data.updateUserAccounts);
    } catch (error) {
      console.error('Error updating user record:', error);
    }
}
*/


  //TODO P0 Send the value to server or perform desired action
  /* */


function populateTextarea(topic) {
  var textarea = document.getElementById("topics");
  if(textarea.value!="null"){
  //TODO revisit if you need this n - I think it adds an extra weird line
    //textarea.value = topic + "\n";
    textarea.value = topic;
  }
}

async function populateTopics(descriptionInput) {
  var descriptionInput = document.getElementById("businessDescription");
  var topicsInput = document.getElementById("businessTopics");
/*
  if(descriptionInput.value!="null"){
  topicsInput.value = descriptionInput.value;
  }
  */
 //console.log('Generating topics from description...');
 const topicGenerationLoader = document.getElementById('topicGenerationLoader');
 topicGenerationLoader.style.display = 'block';
  //Write OpenAI call to generate topics based on this description
  var responseObject;
  try {
    const response = await axios.post(globals_aws_app_api_systemapi_base+'/systemapi/generatetopicsfromdescription', {
      businessDescription: descriptionInput.value
    });
    
    responseObject = response.data.response;
    //console.log('Success: Request sent to POST API generatetopicsfromdescription');
    //console.log(responseObject);
    topicsInput.value = responseObject;
    topicGenerationLoader.style.display = 'none';
    //var loadingelement = document.getElementById('newTweetsLoader');
    //loadingelement.style.display = 'none';
  } catch (error) {
    console.error("An error occurred:", error);
    console.log('Failure: AI API error. We could not generate topics. Try again.');
    topicGenerationLoader.style.display = 'none';
  }  
  
}

function togglePill(pill) {
  //pill.classList.toggle("selected");
}

function boostAutopilot() {
//user_db_id, user_numeric_id, user_random_id, user_accountEmail, user_access_token, user_access_token_secret, entityname, entitydescription, topics
  //console.log('autopilot boost initiated');
  var loadingelementboost = document.getElementById('boostLoader');
  loadingelementboost.style.display = 'block';
  var user_db_id = localStorage.getItem("this_user_id"); 
  var user_accountEmail = localStorage.getItem("accountEmail");
  var user_numeric_id = localStorage.getItem("numeric_id"); //numeric_id;
  var user_random_id = localStorage.getItem("random_id"); //random_id;
  //var user_access_token = localStorage.getItem("twitterAccessToken"); //random_id;  
  //var user_access_token_secret = localStorage.getItem("twitterSecretAccessToken"); //random_id;  
  var user_access_token_secure = localStorage.getItem("twitterTokenSecure"); 
  var user_access_token_secret_secure = localStorage.getItem("twitterTokenSecretSecure");
  var entityname = localStorage.getItem("entityname");
  var entitydescription = localStorage.getItem("entitydescription");
  var topics = localStorage.getItem("entitytopics");
  var twitterUsername = localStorage.getItem("twitterName");
  
  var tweetrandomid = generateRandomNumber();
  var tweetrandomalphanumericid = generateRandomAlphanumeric();

  
  let responseObject;
  try {
    // Prepare the data for the POST request
    const postData = {
      db_id: user_db_id,
      user_numeric_id: user_numeric_id,
      user_random_id: user_random_id,
      accountemail: user_accountEmail,
      //accesstoken: user_access_token,
      //accesstokensecret: user_access_token_secret,
      accesstoken_secure: user_access_token_secure,
      accesstokensecret_secure: user_access_token_secret_secure,
      entityname: entityname,
      entitydescription: entitydescription,
      topics: topics,
      twitterUsername: twitterUsername,
      user_prompted: 'true'
    };
    


    // Send the POST request asynchronously
    /*axios
      .post('https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/autopilotgenerateapi', postData)*/
      //NEW 7-1-23 added .env for execute variables
    axios
      .post(globals_aws_app_api_systemapi_base+'/systemapi/autopilotgenerateapi', postData)
      .then((postResponse) => {
        responseObject = postResponse.data.response;
        //console.log('Success: Request sent to POST API autopilotgenerateapi');
        var loadingelementboost = document.getElementById('boostLoader');
        loadingelementboost.style.display = 'none';
        //trying this
        window.location.reload();
        // Continue with the remaining code or response handling
      })
      .catch((error) => {
        console.error('Error in axios.post request:', error);
        // Handle the error appropriately
      });
  } catch (error) {
    console.error('Error during OpenAI completion:', error);
    // Handle the error appropriately
  }
  //loadingelementboost.style.display = 'none';
}

async function cancelSubscription(subscriptionId) {
  const cancelSubscriptionUrl = `https://api.stripe.com/v1/subscriptions/${subscriptionId}/cancel`;

  try {
    const response = await axios.post(cancelSubscriptionUrl, null, {
      headers: {
        Authorization: `Bearer ${stripeSecretKey}`,
      },
    });
    console.log('Subscription canceled:', response.data);
  } catch (error) {
    console.error('Error canceling subscription:', error.response.data);
    throw error;
  }
}



/* App Starts Here */  
const App = ({ signOut }) => {

  
const datePickerRef = useRef(null);

function forceUIupdates(){
  //if (localStorage.getItem("auth_user_state") === 'true') {
  if(document.getElementById('accountWrapperTwitterName')!=null){
  document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+localStorage.getItem("twitterUsername"))  
  }
  //}

  if(localStorage.getItem("numeric_id")!="null" || localStorage.getItem("numeric_id")!=null){
    fetchYourScheduledTweets(localStorage.getItem("numeric_id"));
    }

}



function addDeleteFunctionality(){
  const elements = document.querySelectorAll('.deleteButtons');
  //alert('test');

  elements.forEach(element => {
    element.onClick = () => {
      // Handle click event here
      // You can define your own logic or function to execute
      alert('hello');
      console.log('Element clicked:', element);
    };
  });  
}



if (localStorage.getItem("just_authenticated") === "true") {
  // If you remove this check, it bugs out
    localStorage.setItem("just_authenticated", "false");
  window.location.reload(true);
}
  //TODO - consider trying this out in useEffect b/c error says React Hook useEffect has a missing dependency: 'base_url'. Either include it or remove the dependency array 
  var base_url = encodeURIComponent(window.location.origin);

  useEffect(() => {

    fetchCurrentDBUserOnLoad(user_email);
    //console.log(localStorage.getItem("twitterUsername"));
  /*  fetchCurrentDBUserOnLoad(user_email)
  .then(() => updateBusinessFieldsAndSettings());
*/

    //TODO need better empty state for new user
  if(localStorage.getItem("numeric_id")!==null){
  fetchYourScheduledTweets(localStorage.getItem("numeric_id"));
  }

    //Hide automated tweets loading image
    var loadingelement = document.getElementById('newTweetsLoader');
    loadingelement.style.display = 'none';
    var loadingelementboost = document.getElementById('boostLoader');
    loadingelementboost.style.display = 'none';

    /*
    document.getElementById('accountWrapperEmail').innerHTML = ("Email: "+user_email);
    document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+user_twitter_username);    
    */

    //Show alerts
if(localStorage.getItem("twitterTokenSecure")==='unknown' || localStorage.getItem("twitterTokenSecure")===null){
        const notConnectedAlertOnLoad = document.getElementById('twitterAuthWarningAlert');
        notConnectedAlertOnLoad .style.display = 'block';
  
        setTimeout(() => {
          notConnectedAlertOnLoad .style.display = 'none';
        }, 3000);
}
    
if(localStorage.getItem("just_started_authenticated"==="true")){
      const isConnectedAlertOnLoad = document.getElementById('twitterAuthSuccessAlert');
      isConnectedAlertOnLoad .style.display = 'block';

      setTimeout(() => {
        isConnectedAlertOnLoad.style.display = 'none';
      }, 3000);

      localStorage.setItem("just_started_authenticated","false");
  }

  if(localStorage.getItem("just_authenticated"==="failed")){
    const failedConnectedAlertOnLoad = document.getElementById('twitterAuthFailedAlert');
    failedConnectedAlertOnLoad .style.display = 'block';

    setTimeout(() => {
      failedConnectedAlertOnLoad.style.display = 'none';
    }, 3000);

    //Consider leaving until it succeeds or commenting out
    //localStorage.setItem("just_authenticated","");
}

if(localStorage.getItem("stripeCustomerJustPaid"==="true")){
  const stripePaymentSuccessAlertOnLoad = document.getElementById('paymentSuccessAlert');
  stripePaymentSuccessAlertOnLoad.style.display = 'block';

  setTimeout(() => {
    stripePaymentSuccessAlertOnLoad.style.display = 'none';
  }, 3000);
}

  if(localStorage.getItem("stripeCustomerJustCanceledPayment"==="true")){
    const stripePaymentCanceledAlertOnLoad = document.getElementById('paymentCanceledAlert');
    stripePaymentCanceledAlertOnLoad.style.display = 'block';
  
    setTimeout(() => {
      stripePaymentCanceledAlertOnLoad.style.display = 'none';
    }, 3000);  
}

    if (datePickerRef.current) {
      flatpickr(datePickerRef.current, {
        // Configuration options for Flatpickr
        dateFormat: 'Y-m-d',
        minDate: 'today'
        // Add more options as needed
        /* // Clean up Flatpickr instance on component unmount
  return () => {
    flatpickrInstance.destroy();
  };*/
      });
    }
    //TODO P0 abstract this base API URL
    //NEW 7-1-23 abstracted 
    //process.env.AWS_APP_API_SYSTEMAPI_BASE_URL+
    //fetchAuthUrl('https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/test?origin_domain='+base_url);
    fetchAuthUrl(globals_aws_app_api_systemapi_base+'/systemapi/test?origin_domain='+base_url);
    checkIfAuth();

    //Add user to DB if they are new
    //TODO this needs to be stronger; right now I added a 2nd instance of a user.
    if(localStorage.getItem("user_exists")!=='true'){
      //TODO this is returning null and it should have an ID by now in some cases
      //console.log(localStorage.getItem("this_user_id"));
      if(localStorage.getItem("this_user_id")===null){
        //This line below is key to not re-adding the user; can clean up the rest later
        if(sessionStorage.getItem('added_user')!=='true'){
          //Added 6-25 to try to ensure user really doesn't exist.
          if(localStorage.getItem('entitydescription')==='' || localStorage.getItem('entitydescription')===null || localStorage.getItem('entitydescription')==='null' || localStorage.getItem('entitydescription')===undefined){ 
    addUser();
    fetchCurrentDBUserOnLoad(user_email);
    //console.log(user_email);
    //Added 6-18 in order to get the user DB data showing up on page and in local after the user is added; is a test
    if (typeof window !== 'undefined' && window.location) {
      // Refresh the page
      //window.location.reload();
    }
        }
      }
    }
    }

    

    //flatpickr('#datePicker', { dateFormat: 'Y-m-d', minDate: 'today' });
    //fillStatsBox();
    

    //Update account info
    document.getElementById('accountWrapperEmail').innerHTML = ("Email: "+user_email);
    document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+user_twitter_username); 
    
    
    
    const twitterNameURL = document.getElementById('twitterNameURL');
    twitterNameURL.href = `https://twitter.com/${user_twitter_username}`;     

    //if(localStorage.getItem('twitterAccessToken').length<8){
      /*
    if(localStorage.getItem('twitterAccessToken')=='unknown' || localStorage.getItem('twitterAccessToken')==null){
      document.getElementById('twitterAuthenticationStatus').innerHTML = ("&#10060; ");
      document.getElementById('twitterAuthStartButton').append(" (Required.)");
    }else{
      //Success
      document.getElementById('twitterAuthenticationStatus').hidden = 'true';
      //document.getElementById('twitterAuthenticationStatus').innerHTML = ("&#9989; ");
      //document.getElementById('twitterAuthStartButton').append(" (Not required.)");
    }
    */
    fetchCurrentDBUserOnLoad(user_email).then(() => {
      updateBusinessFieldsAndSettings();
      updateTwitterStatus();
      forceUIupdates();
      //testemail();
      //testdailyemail();
      //console.log('test email tried');
      flatpickr('#datePicker', { dateFormat: 'Y-m-d', minDate: 'today' });
      if(localStorage.getItem("entitytopics")!="null"){
      populateTextarea(localStorage.getItem("entitytopics"));
      }
      //console.log(user_email);
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });

    
    /*
    document.getElementById('businessName').value = localStorage.getItem("entityname");
    document.getElementById('businessDescription').value =  localStorage.getItem("entitydescription");
    document.getElementById('businessWebsite').value =  localStorage.getItem("entitywebsite");
    document.getElementById('businessTopics').value =  localStorage.getItem("entitytopics");
    document.getElementById('businessGoal').value =  localStorage.getItem("entitygoal");
    */
    //updateBusinessFieldsAndSettings();

    /*
    const referralUrl = document.referrer;
    console.log(referralUrl);
    if (referralUrl.includes('callback') || localStorage.getItem("just_started_authenticated") === "true" && localStorage.getItem("refreshCount") != "1") {
      window.location.reload(true).then(() => {
        localStorage.setItem("refreshCount", "1");
        console.log('refreshed bc'+referralUrl);
      localStorage.setItem("just_started_authenticated", "false");
      });
*/
  
/*

const referralUrl = document.referrer;
console.log(referralUrl);
if ((referralUrl.includes('callback') || localStorage.getItem("just_started_authenticated") === "true") && localStorage.getItem("refreshCount") !== "1") {
  localStorage.setItem("refreshCount", "1");
  localStorage.setItem("just_started_authenticated", "false");
  setTimeout(() => {
    console.log('Refreshed because of ' + referralUrl);
    window.location.reload(true);
  }, 100); // Adjust the delay if necessary
}
*/

      /*
      setTimeout(() => {
        console.log('Refreshed because of ' + referralUrl);
        localStorage.setItem("just_started_authenticated", "false");
      }, 100); // Adjust the delay if necessary
      window.location.reload(true);
    }
      */
    
    
//FYI added couple other functions to ensure they occurred 
  }, [base_url, forceUIupdates(), addDeleteFunctionality()]);
  

addDeleteFunctionality();

const updateTwitterStatus = () => {
  if (localStorage.getItem("twitterTokenSecure") !== "unknown" && localStorage.getItem("twitterTokenSecure") !== null && localStorage.getItem("twitterTokenSecure") !== "null") {
    const twitterAuthenticationStatus = document.getElementById('twitterAuthenticationStatus');
    const twitterAuthStartButton = document.getElementById('twitterAuthStartButton');
    twitterAuthenticationStatus.style.display = 'none';
    twitterAuthStartButton.style.display = 'none';
    //console.log('Twitter authentication status: connected');
    if(localStorage.getItem("just_started_authenticated")==='true'){
      const notConnectedAlertOnLoad = document.getElementById('twitterAuthWarningAlert');
      notConnectedAlertOnLoad.style.display = 'none';
      const isConnectedAlertOnLoad = document.getElementById('twitterAuthSuccessAlert');
      isConnectedAlertOnLoad .style.display = 'block';
      setTimeout(() => {
        isConnectedAlertOnLoad.style.display = 'none';
      }, 3000);
      localStorage.setItem("just_started_authenticated", "false");
    }
  } else {
    const notConnectedAlertOnLoad = document.getElementById('twitterAuthWarningAlert');
    notConnectedAlertOnLoad.style.display = 'block';
    document.getElementById('twitterAuthenticationStatus').innerHTML = ("&#10060; ");
    document.getElementById('twitterAuthStartButton').append(" (Required.)");
    //console.log('Twitter authentication status: not connected');
    setTimeout(() => {
      notConnectedAlertOnLoad.style.display = 'none';
    }, 3000);
  }
}

  const updateBusinessFieldsAndSettings = () => {

    
  // Retrieve values from localStorage
var businessName = localStorage.getItem("entityname");
var businessDescription = localStorage.getItem("entitydescription");
var businessWebsite = localStorage.getItem("entitywebsite");
var businessTopics = localStorage.getItem("entitytopics");
var businessGoal = localStorage.getItem("entitygoal");

// Check and set default values if null or empty
if (businessName==="null") {
  businessName = "";
}

if (businessDescription==="null") {
  businessDescription = "";
}

if (businessWebsite==="null") {
  businessWebsite = "";
}

if (businessTopics==="null") {
  businessTopics = "";
}

/*
if (businessGoal==="null") {
  businessGoal = "Enter your business goal";
}
*/

// Set values to corresponding elements
document.getElementById("businessName").value = businessName;
document.getElementById("businessDescription").value = businessDescription;
document.getElementById("businessWebsite").value = businessWebsite;
document.getElementById("businessTopics").value = businessTopics;
//document.getElementById("businessGoal").value = businessGoal;


    if (localStorage.getItem("autopilotEnabled") === "true") {
      document.getElementById("autopilotToggle").checked = true;
    } else {
      document.getElementById("autopilotToggle").checked = false;
    }
  };

  //Needs review 7-2-23
  //console.log(localStorage.getItem("twitterUsername"));
  if(document.getElementById('accountWrapperTwitterName')!=null){
    document.getElementById('accountWrapperTwitterName').innerHTML = ("Username: "+localStorage.getItem("twitterUsername"))  
    }
    //}

    if(localStorage.getItem("numeric_id")!="null" || localStorage.getItem("numeric_id")!=null){
    fetchYourScheduledTweets(localStorage.getItem("numeric_id"));
    }


  const YOUR_STRIPE_SECRET_KEY='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';
  
  let stripeCustomers;
  let thisStripeCustomer;
  const checkCustomerExists = async (email) => {
  try {
    const response = await axios.get('https://api.stripe.com/v1/customers', {
      params: {
        email: email,
      },
      headers: {
        //Authorization: `Bearer ${YOUR_STRIPE_SECRET_KEY}`,
        Authorization: `Bearer ${YOUR_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Check if any customers match the search criteria
    stripeCustomers = response.data.data;
    if (stripeCustomers.length > 0) {
      // User is a customer, further process the customer data
      thisStripeCustomer = stripeCustomers[0];
      //console.log(thisStripeCustomer);
    } else {
      // User is not a customer
      //console.log('User is not a customer in Stripe');
    }
  } catch (error) {
    // Handle any errors that occur during the request
    //console.error('Error checking customer:', error);
  }
};

var stripeCustomer;
var subscriptions;
var thisSubscription;
var thisSubscriptionPriceId;
var thisSubscriptionProductId;

async function getStripeCustomerInfo(customerId) {
  try {
    stripeCustomer = await stripeInstance.customers.retrieve(customerId);
    //console.log('Retrieved customer:', stripeCustomer);
  } catch (error) {
    //console.error('Error retrieving customer:', error);
  }
}

async function getCustomerSubscriptions(customerId) {
  try {
    subscriptions = await stripeInstance.subscriptions.list({
      customer: customerId,
    });
    //console.log(subscriptions.data[0]);
    thisSubscription = subscriptions.data[0];
    //thisSubscriptionPriceId = subscriptions.data[0].plan.id;
    thisSubscriptionPriceId = subscriptions.data[0].plan.id;
    thisSubscriptionProductId = subscriptions.data[0].plan.product;
    if(stripeCustomerId!=undefined){
    if (thisSubscription.canceledAt==null){
    document.getElementById('subscriptionStatusWrapper').innerHTML = "Active Customer (thank you!)";
    }
  }
    //console.log('Retrieved subscriptions:', subscriptions.data);
  } catch (error) {
    //console.error('Error retrieving subscriptions:', error);
  }
}
//console.log("hello"+thisSubscriptionPriceId);


/*
  try {
subscriptions.data.forEach((subscription) => {
  console.log('Subscription ID:', subscription.id);
  console.log('Status:', subscription.status);
  console.log('Customer ID:', subscription.customer);
  // Access other subscription properties as needed
  console.log('------------------------');
});
} catch (error) {
console.error('Error listing subscriptions:', error);
}
*/

/*
var subscription_id = subscriptions.data[0].id
var subscription_price_id = subscriptions.data[0].plan.id;
var subscription_product_id = subscriptions.data[0].plan.product;
console.log(subscription_id + subscription_price_id + subscription_product_id);
*/

//getStripeCustomerInfo("cus_O7X8q5RILRvpZJ");

/*
const getCustomerInfo = async (customerId) => {
  try {
    const response = await axios.get(`https://api.stripe.com/v1/customers/${customerId}`, {
      params: {
        customerId: customerId,
      },
      headers: {
        //Authorization: `Bearer ${YOUR_STRIPE_SECRET_KEY}`,
        Authorization: `Bearer ${YOUR_STRIPE_SECRET_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Access the customer information from the response data
    const customer = response.data;
    console.log('Retrieved customer:', customer);
  } catch (error) {
    console.error('Error retrieving customer:', error.response.data.error);
  }
};
*/

// Call the checkCustomerExists function with the user's email
//console.log(user_email);
var stripeCustomerObject = checkCustomerExists(user_email);
var stripeCustomerId = stripeCustomerObject.id;
//console.log(stripeCustomerObject);
//console.log(stripeCustomerId);
getCustomerSubscriptions(stripeCustomerId);

//getCustomerInfo(stripeCustomerId);
//checkCustomerExists('user@example.com');
  

  const [tweetText, setTweetText] = useState('');
  
  const handleCustomTweetChange = (event) => {
    setTweetText(event.target.value);
  };

  const handlePostClick = () => {
    //const twitterAccessToken = localStorage.getItem("twitterAccessToken");
    //const twitterSecretAccessToken = localStorage.getItem("twitterSecretAccessToken");
    const twitterAccessTokenSecure = localStorage.getItem("twitterTokenSecure");
    const twitterSecretAccessTokenSecure = localStorage.getItem("twitterTokenSecretSecure");
    //if(twitterAccessToken !== '' && twitterSecretAccessToken !== ''){
    if(twitterAccessTokenSecure !== '' && twitterSecretAccessTokenSecure !== ''){
    try{
    //makeClientPostRequest(tweetText, twitterAccessToken, twitterSecretAccessToken);
    makeClientPostRequest(tweetText, twitterAccessTokenSecure, twitterSecretAccessTokenSecure);
    }catch(error){
      console.error(error);
    }
  }
  };
  
  
 
  /* HTML Start */
  return (
    <View className="App">
      <div id='appContainer'>

    <div id="successAlert" className="alert success">
    Tweet saved successfully! Refresh to see updated schedule.
    </div>

    <div id="twitterAuthSuccessAlert" className="alert success">
    Twitter account authenticated!
    </div>

    <div id="twitterAuthWarningAlert" className="alert warning">
    Account not connected to Twitter. Authenticate now!
    </div>

    <div id="twitterAuthFailedAlert" className="alert warning">
    Account failed Twitter authentication. Try again! 
    </div>

    <div id="profilesuccessAlert" className="alert success">
    Your profile was successfully updated! Your autopilot tweets will be better now.
    </div>

    <div id="paymentSuccessAlert" className="alert success">
    Congrats and welcome to the Postmatica community. Your subscription was successful.
    </div>

    <div id="paymentCanceledAlert" className="alert success">
    Your subscription was canceled.
    </div>

    <div id="tweetNowSuccessAlert" className="alert success">
    Tweeted posted successfully! Check your Twitter account.
    </div>

    <div id="deleteTweetSuccessAlert" className="alert success">
    Your tweet was deleted and will not be posted. Refresh to update.
    </div>

      <div id='postmaticaNameContainer'>
      <span className='postmaticaName'>Postmatica</span>
      <br />
      <span>Your AI Social Media Manager <span className='emojiRendering'>&#x2764;&#xFE0F; &#x1F5A5; &#x1F680;</span></span>
      <br /><br />
      <hr className='partialHR' />
      <br />
      </div>
      
      <div>
      <div id='commandCenterContainer'>
      <span className="twitterNameWrapper">
      <b><span id='accountWrapperTwitterName'></span>&nbsp;&nbsp;<a id='twitterNameURL' target='_blank' href=''><img className="twitterPageLogo" src={twitterlogo} alt="Logo" /></a></b>
      </span>
      <br />
      <div id='twitterAuthWrapper'>
      <span id='twitterAuthenticationStatus'></span>
      <a href='' id='twitterAuthStartButton' target='_blank'></a>
      </div>

      <br />
      <div className="statistics-container">
  <div className="box">
    <div className="number" id="box1-number">0</div>
    <div className="label" id="box1-label">Total Posts</div>
  </div>
  <div className="box">
    <div className="number" id="box2-number">0</div>
    <div className="label" id="box2-label">Posted</div>
  </div>
  <div className="box">
    <div className="number" id="box3-number">0</div>
    <div className="label" id="box3-label">Upcoming</div>
  </div>
</div>
</div>
<center>


      <br />
      <hr className='partialHR' />

      

      <div id='autopilotContainer'>
      <span><h2>Activate: Autopilot Mode</h2></span>
      <span>Custom AI-generated tweets. 3x/day. Magical.</span><br /><br />
      <label className="toggle-switch">
    <input type="checkbox" id="autopilotToggle" onChange={handleAutopilotToggleChange} />
    <span className="slider"></span>
    </label>
    <br /><br />
    <i><span onClick={boostAutopilot}>Try a Boost Right Now &#x26A1;	</span></i>
    <span id='boostLoader'><br /> <img src={loader} /><br /><i>&#x1F916; &#x1FAE1; &#x1F440; Generating your tweets (can take 30s). Refresh after...</i></span>
      </div>

      
      <br />
      <hr className='partialHR' />


      

      <div id='tweetReviewContainer'>  
      <span><h2>Review: Scheduled Tweets</h2></span>
      <span id='scheduledTweetsTableContainer'></span>
      </div>
      
      
      <br /><br />
      <hr className='partialHR' />

      

      <div id='tweetCreationContainer'>
      <span><h2>Create: AI-Generated Tweets</h2></span>
      <label htmlFor="topics"></label>
      <textarea id="topics" name="topics" className="topicsClass" placeholder="Enter relevant topics separated by a comma e.g. fintech, consumer spending, savings" rows="2" cols="25"></textarea><br />
    <div id='topicSuggestionPills'>
    <div className={`pill`} onClick={() => { populateTextarea("ecommerce, fashion, summer style"); togglePill(this); }}>ecommerce, fashion, summer style &#128717;&#65039;</div>
    <div className={`pill`} onClick={() => { populateTextarea("tech, venture capital, AI"); togglePill(this); }}>tech, venture capital, AI &#129489;&#8205;&#128187;</div>
    <div className={`pill`} onClick={() => { populateTextarea("restaurants, fine dining, pasta"); togglePill(this); }}>restaurants, fine dining, pasta &#127837;
</div>
    <div className={`pill`} onClick={() => { populateTextarea("productivity, tasks, goals"); togglePill(this); }}>productivity, tasks, goals &#127919;
</div>
    <div className={`pill`} onClick={() => { populateTextarea("Enter a comma-separated list of topics relevant for your business..."); togglePill(this); }}>your topics &#9999;&#65039;
</div>
    </div>

    <br />
    <div id='generatedTweetPreferences'>
    <label>
      <input type="checkbox" className='generatedTweetPreferenceSelection' id='generatedTweetPreferenceSelectionEmojis'  />
      Include Emojis
      </label>

      <label>
      <input type="checkbox" className='generatedTweetPreferenceSelection' id='generatedTweetPreferenceSelectionHashtags'  />
      Include Hashtags
      </label>

      <label>
      <input type="checkbox" className='generatedTweetPreferenceSelection' id='generatedTweetPreferenceSelectionCaps'   />
      Include All Caps
      </label>
      </div>
      <br />
      <button id='generateTweetButton' className='secondaryButton generateTweetsButton' onClick={generateAIcontentretries}>Generate Tweets &#128640; </button>
      <br />
      <span id='newTweetsLoader'><br /> <img src={loader} /><br /><i>&#x1F916; &#x1FAE1; &#x1F440; Generating your tweets (can take 30s)...</i></span>
      <br />
      <span id='generatedTweetsTableContainer'></span>
      </div> 
      </center>  
      </div>

      <br />
      <hr className='partialHR' />
      <br />

      <div id='accountContainer'>
      <h2>Account Info</h2>
      <div id='accountContainer'>
      <span id='accountWrapperEmail'></span>
      <br />
      <div id='twitterAuthWrapper2'>
      <span id='twitterAuthenticationStatus2'></span>
      <a href='' id='twitterAuthStartButton2' target='_blank'>Twitter: <u>Connect Account</u></a>
      </div>

      <div>
      <span>Product Emails: 
      <label className="">
      <input type="checkbox" id="productEmailToggle" onChange={handleEmailToggleChange} />
    </label>  
      </span>
      </div>

      <span id='subscriptionWrapper'>
      Your subscription: <span id='subscriptionStatusWrapper'>Free Trial
      <br />
      <span className='subscribeButtonModifier'>
      <form action="https://7ewzqvoxmg.execute-api.us-east-2.amazonaws.com/main/systempayments/create-checkout-session" method="POST">
      <button className='tertiaryButton' type="submit">
        <b>Subscribe Now</b>
      </button><br />
      <span className='depriText'>$1000/mo. Cancel anytime. Email sales@postmatica.com with any questions or partnership inquiries.</span>
    </form>
    </span>
    </span>
      </span>
      <br /><br />
      <div id='businessInformation'>
      <div>
    <label htmlFor='businessName'>Business Name:</label>
    <input type='text' id='businessName' placeholder='What is your company name?' />
  </div>
  <div>
    <label htmlFor='businessWebsite'>Business Website:</label>
    <input type='text' id='businessWebsite' placeholder='e.g. https://postmatica.com' />
  </div>
  <div>
    <label htmlFor='businessDescription'>Business Description:</label>
    <textarea id='businessDescription' placeholder='Describe it, please.'></textarea>
  </div>
  <div>
    <label htmlFor='businessTopics'>Business Topics:</label>
    <textarea id='businessTopics' placeholder='Comma-separated list. These will be used to train the AI and generate tweets. e.g. travel, Europe, vacations'></textarea>
    <span className={`depriText`} onClick={() => { populateTopics() }}>Boost topics using description<span id='topicGenerationLoader'><br /><img className='topicGenerationLoaderImg' src={loader} /></span></span>
  </div>
  <div>
    <label htmlFor='businessGoal'>Goal:</label>
    <select id='businessGoal'>
      <option value='Acquire Customers'>Acquire Customers</option>
      <option value='Brand Awareness'>Brand Awareness</option>
      <option value='Thought Leadership'>Thought Leadership</option>
    </select>
  </div>

  <button className='tertiaryButton' onClick={updateBusinessProfile}>Update Profile</button>
      
      </div>
      <div hidden>
      <span>
      <textarea id="customTweetInput" onChange={handleCustomTweetChange} value={tweetText}></textarea>
       <br />
       <button onClick={handlePostClick}>Send Post Tweet</button>
      </span>
      <br />
      <button onClick={() => cronpost()}>Trigger Post Request</button>
      <br /><br />
      <span>Date picker:</span>
      <input ref={datePickerRef}></input>
      
      </div>
      </div>
      <br />
      <Button onClick={handleSignOut}>Sign Out</Button>
      <br /><br />
      </div>
      </div>
    </View>
  );
};

export default withAuthenticator(App);
