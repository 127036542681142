import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PublicSite from './PublicSite.js';

import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

var active_user = false;

// Function to handle sign-out
/*
const handleSignOut = async () => {
  try {
    // Perform any clean-up tasks or custom logic here

    // Sign out the user
    await Auth.signOut();

    // Additional custom logic after sign-out

    // Redirect or update the UI as needed
    // Example:
    window.location.href = '/login'; // Redirect to the login page
  } catch (error) {
    console.log('Error signing out:', error);
  }
};
*/

Auth.currentAuthenticatedUser({
  bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
})
  .catch((err) => {
    console.log(err);
    if(window.location.href.includes("dashboard")) {
      window.location.href = "/signup";
    };
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

//removed strict mode b/c caused 2x twitter api calls
if(active_user === false){
  root.render(
      <PublicSite />
  )}
  else{
    root.render(
        <App />
    )}
