import React from 'react'

export default function PaymentCancellation() {
    localStorage.setItem("stripeCustomer", "true");
    localStorage.setItem("stripeCustomerJustCanceledPayment", "true");

    setTimeout(() => {
        window.location.href = '/dashboard';
      }, 2000);
    
  return (
    <div>Payment Cancellation</div>
  )
}
