import React from 'react'

export default function Privacy() {
  return (
    <div>

<b>Privacy Policy</b><br />
<br />
This Privacy Policy ("Policy") describes how Stacksort LLC DBA Postmatica ("Postmatica," "we," "us," or "our") collects, uses, and shares personal information when you use our AI Tweet Posting Service ("Service"). By using our Service, you agree to the collection and use of your personal information as described in this Policy. If you do not agree with the practices described herein, please do not use our Service.
<br />
1. Information We Collect
<br /><br />
1.1 Personal Information
<br /><br />
When you create an account and use our Service, we may collect personal information, including but not limited to your name, email address, and Twitter account information. We require this information to provide the Service and to communicate with you regarding your account and the generated tweets.
<br /><br />
1.2 Marketing Materials
<br /><br />
To generate tweets on your behalf, we may collect marketing materials provided by your company, such as slogans, product descriptions, or promotional messages. These materials are used solely for the purpose of generating content for your Twitter account.
<br /><br />
1.3 Usage Data
<br /><br />
We may collect non-personal information about your use of the Service, including IP addresses, browser type, operating system, and the pages you visit on our website. This information helps us improve the functionality and performance of our Service.
<br /><br />
2. Use of Information
<br /><br />
2.1 Provision of Service
<br /><br />
We use the personal information and marketing materials collected to provide the Service and generate tweets on your behalf. This includes utilizing the marketing materials provided to generate content for your Twitter account and communicating with you regarding the Service.
<br /><br />
2.2 Service Improvement
<br /><br />
We may use aggregated and anonymized usage data to analyze trends, monitor the effectiveness of our Service, and enhance its features and functionality. This data does not identify individual users and is used for statistical purposes only.
<br /><br />
2.3 Communication
<br /><br />
We may use your email address to send you important updates, notifications, or other information related to the Service. You can opt-out of receiving non-essential communications by following the instructions provided in the email.
<br /><br />
3. Information Sharing
<br /><br />
3.1 Third-Party Service Providers
<br /><br />
We may engage third-party service providers to assist us in delivering the Service or to perform related services. These providers may have access to your personal information but are strictly prohibited from using it for any other purpose.
<br /><br />
3.2 Legal Compliance
<br /><br />
We may disclose your personal information if required by law or in good faith belief that such action is necessary to comply with legal obligations, protect our rights, investigate fraud, or respond to a government request.
<br /><br />
3.3 Business Transfers
<br /><br />
In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website of any change in ownership or the use of your personal information.
<br /><br />
4. Data Security
<br /><br />
We take reasonable measures to protect the personal information we collect and maintain from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
<br /><br />
5. Your Privacy Choices
<br /><br />
You have the right to access, update, or delete your personal information stored by us. You can make these changes by logging into your account or by contacting us directly. Please note that deleting your account may result in the permanent loss of data associated with your account.
<br /><br />
6. Children's Privacy
<br /><br />
Our Service is not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child, we will take reasonable steps to delete it.
<br /><br />
7. Changes to this Privacy Policy
<br /><br />
We may update this Policy from time to time to reflect changes in our practices or legal obligations
<br /><br />
<br /><br />

    </div>
  )
}
