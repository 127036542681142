/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://a7vnk36jgvgnjaeelumydqyjc4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-k6zgcuxbtnfkvde4sbxh6vpduq",
    "aws_cloud_logic_custom": [
        {
            "name": "systemapi",
            "endpoint": "https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "systempayments",
            "endpoint": "https://7ewzqvoxmg.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:fbad1442-cca9-4f9e-b0c0-6265668f923e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_fJguZOHaD",
    "aws_user_pools_web_client_id": "4cir8g9kc6cluids0u77gv5hd9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
