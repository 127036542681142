import React from 'react'

export default function PaymentSuccess() {
localStorage.setItem("stripeCustomer", "true");
localStorage.setItem("stripeCustomerJustPaid", "true");

setTimeout(() => {
    window.location.href = '/dashboard';
  }, 2000);

  return (
    <div>Payment Success</div>
  )
}
