import React from 'react'
//import './main.css';

export default function Terms() {
  return (
    <div >
<b>Terms of Service</b><br /><br />

Welcome to our AI Tweet Posting Service provided by Stacksort LLC DBA Postmatica! Please carefully read the following Terms of Service ("Agreement") before using our platform. By accessing or using our service, you agree to be bound by these terms. If you do not agree with any part of this Agreement, you may not use our service.
<br /><br />
Service Description<br /><br />
Our AI Tweet Posting Service allows companies to automate their Twitter presence by posting tweets on their behalf using artificial intelligence technology. We utilize the marketing materials provided by the company to generate and post tweets.
<br /><br />
Use of the Service<br />
2.1 Eligibility
<br /><br />
You must be at least 18 years old to use our service. By using the service, you represent and warrant that you are at least 18 years of age.
<br /><br />
2.2 Account Creation
<br /><br />
To access and use our service, you must create an account. You are responsible for providing accurate and complete information during the registration process. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
<br /><br />
2.3 Company Materials and AI Generated Tweets
<br /><br />
By using our service, you grant us the right to access, use, and reproduce your company's marketing materials solely for the purpose of generating and posting tweets on your behalf. Our AI technology generates tweets based on the provided materials, and we do not guarantee the accuracy, appropriateness, or effectiveness of the generated content. You are responsible for reviewing and approving the generated tweets before they are posted.

Content Ownership and Liability
3.1 Company's Responsibility
<br /><br />
You understand and acknowledge that you are solely responsible for the content posted on your Twitter account through our service. You retain all ownership rights to the marketing materials provided to us for generating tweets. We do not claim ownership over any of the content you post through our service.

3.2 Our Responsibility
<br /><br />
While we strive to provide a reliable and accurate service, we are not responsible for the content generated by our AI technology or how people respond to it. We do not endorse, represent, or guarantee the completeness, accuracy, or reliability of any content posted through our service. You acknowledge and agree that you use our service and rely on any content posted at your own risk.
<br /><br />
User Conduct
4.1 Prohibited Activities
<br /><br />
When using our service, you agree not to engage in any of the following activities:
<br /><br />
a) Violating any applicable laws or regulations;<br /><br />
b) Posting or transmitting any unlawful, harmful, defamatory, obscene, or otherwise objectionable content;<br /><br />
c) Impersonating any person or entity, or falsely representing your affiliation with any person or entity;<br /><br />
d) Interfering with or disrupting the service or servers or networks connected to the service;<br /><br />
e) Attempting to gain unauthorized access to any part of the service or any other system or network.<br /><br />
<br /><br />
4.2 Suspension or Termination
<br /><br />
We reserve the right to suspend or terminate your access to our service, without prior notice, if we have a reasonable belief that you have violated any provision of this Agreement or engaged in any prohibited activities.
<br /><br />
Intellectual Property<br /><br />
All intellectual property rights, including copyrights, trademarks, and patents, associated with our service, its software, and the generated content, belong to us or our licensors. You may not reproduce, modify, distribute, or create derivative works of any part of our service or its content without our prior written consent.
<br /><br />
Limitation of Liability<br /><br />
To the maximum extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use or inability to use our service, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, even if we have been advised of the possibility of such damages
<br /><br />
    </div>
  )
}
