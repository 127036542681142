import React from 'react'
const { Configuration, OpenAIApi } = require('openai');

const configuration = new Configuration({
    organization: "org-hFLIjMI6QpixpETwpp5VDOIa",
    //apiKey: process.env.OPENAI_API_KEY
    apiKey: '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'
});
const openai = new OpenAIApi(configuration);

export default function APITests() {
    
    async function hello(){
        console.log("hello");
    }
    
    async function apiContent(){
    try {
        const chatCompletion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: "Hello world" }],
        });
        const response = chatCompletion.data.choices[0].message;
        console.log(response);
        //res.json({ response }); // Send the response back to the client
      } catch (error) {
        console.error(error);
        //res.status(500).json({ error: 'An error occurred' });
      }
    }
    
    async function say5funnythings(){
    
    const prompt = "Say 5 funny things";
    const messages = [
      { role: "system", content: prompt },
    ];

    const chatCompletion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages,
      max_tokens: 25 * 20, // Generate 25 tweets (assuming an average length of 10 tokens per tweet)
    });

    const response = chatCompletion.data.choices
      .map((choice) => choice.message.content)
      .join("\n");

    console.log(response);
    }

    async function convertDescriptionToTopics(){

      //var { descriptionInput } = req.body;
      console.log('starting...');
      const scribeBusinessDescription = `Scribe is a revolutionary process documentation tool that builds how-to guides and training materials in seconds. With the Scribe extension or desktop app, you can generate visual documents with screenshots, text and click indicators.`;
      const prompt = "Generate 25 specific, lower-cased, 1-2 word topics from this description and return it as a single comma-separated list. You can use synonyms and be creative. " + scribeBusinessDescription;
      const messages = [
        { role: "system", content: prompt },
      ];
  
      const chatCompletion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages,
        max_tokens: 200,
      });
  
      const response = chatCompletion.data.choices
        .map((choice) => choice.message.content)
        .join("\n");
  
      console.log(response);
      console.log('done');
      }
  

    async function rewriteContentAsTweets(){
      console.log('starting...')
      
      //var inventiveBlogPost2 = `Title: Embedded Analytics vs. AI-Powered Data Apps: Unveiling the Power of Data-driven Insights`;

      var scribeBlogPost1 = `D300, a public school district located in the Chicago area, spans 27 schools and 20,000 students from pre-K to 12th grade. They have a dedicated IT department responsible for helping 3,000 faculty members access and use various technologies and tools.

It's no small feat — made even more difficult by an arduous process of building training and process materials. Thanks to Scribe, they no longer waste hours taking screenshots and video screen shares with Camtasia and WeVideo, or manually creating how-to guides.

Scribe's revolutionary solution has alleviated headaches and transformed D300's workflow for the better.

The D300 teams that use Scribe
There are three core teams in D300's IT department that rely on Scribe to enhance their faculty support:

‍Student Information Specialists: This team focuses on training end users to effectively enter required information and data into the student information system to accurately report to the Illinois State Board of Education.‍
Help Desk Specialists: These specialists are responsible for providing service and support for technologies employed by staff and students — including digital curriculum applications, informational data systems and classroom/instructional technology.‍
Digital Learning Coaches: This team assists teachers in integrating technology into instructional programs. They aid in both planning and implementation.
🎓 What is Scribe?: Scribe is an AI-powered process documentation tool that builds how-to guides and training materials in seconds. With the Scribe extension or desktop app, you can generate visual documents with screenshots, text and click indicators.
Use Case 1: Internal knowledge base
Recently, D300 implemented a new knowledge base. With Scribe, they successfully incorporated comprehensive how-to guides into every page, ensuring faculty members were equipped with the knowledge to navigate their new processes.

Before Scribe, the process of building these guides was extremely time-consuming. It involved:

Taking screenshots.
Cropping.
Annotating.
Writing instructions.
This process required approximately 30 minutes per guide, sometimes even stretching to 1 or 2 hours for additional review.

With Scribe, they have significantly reduced the time investment to just 10 minutes per guide.

"Scribe is a game-changer. It eliminates the need for multiple steps, such as taking screenshots, cropping, annotating, and writing instructions, just to provide a single step for the end user to follow."
— Jahan Rodriguez, Help Desk Manager
Furthermore, Scribe's ease of use allows the D300 team to customize their how-to guides based on unique faculty needs, emphasizing district-specific processes and procedures. 

Scribe’s analytical data allows the D300 team to monitor how many views a Scribe has. This has been extremely valuable in helping the D300 team see the success of each guide. Alternatively, when a guide is not highly viewed, they can solicit user feedback as to why and implement needed updates or changes.

Use Case 2: Keeping Documentation Up to Date
A persistent challenge that the D300 team faced was the ever-changing nature of their software. This meant that much of the effort invested in creating how-to guides became obsolete when the user interface or processes changed.

Scribe enables the team to effortlessly maintain up-to-date documentation. When a change is implemented in D300’s PowerSchool Products (their Student Information and Learning Management Systems), affecting the majority of initial steps in their Scribes, they no longer lose hours creating new documentation. The team can promptly re-capture the first step of every Scribe in just a few seconds, ensuring all documentation remains current.

"Scribe has transformed a previously daunting task into a manageable job." — Marti Whitehouse, Student Information Specialist
Recognizing the inevitability of software changes, Scribe is a seamless solution for keeping documentation relevant and accurate.

Use Case 3: Making it easy for faculty to learn
When the registrar sends out a 30+ step guide on how to enroll students, other members of their district are able to easily follow along with the ‘Guide Me’ feature.

Clicking ‘Guide Me’ takes the viewer to the website the process is on, where they can scroll through the Scribe in a side panel. This ensures nobody has to switch tabs and can easily follow the process all the way through to completion. 

Conclusion
The D300 team is able to use Scribe to better support the district faculty in enabling everyone to correctly follow processes. So far, the team has created 425 Scribes that have been viewed 4,100 times (and counting!).

Scribe has given the D300 team superpowers and allowed them to provide better support in less time.`;


      var inventiveBlogPost = `Title: Embedded Analytics vs. AI-Powered Data Apps: Unveiling the Power of Data-driven Insights

Introduction (100 words):
In today's data-driven world, organizations are constantly seeking ways to extract meaningful insights from their data to make informed decisions. Two popular approaches that empower businesses with actionable intelligence are embedded analytics and AI-powered data apps. Both methods enable users to leverage data effectively, but they differ in their functionality, implementation, and capabilities. In this blog post, we will delve into the world of embedded analytics and AI-powered data apps, exploring their unique features, benefits, and use cases, to help you understand which approach aligns best with your business objectives.

Embedded Analytics (200 words):
Embedded analytics is a strategy that integrates data analysis capabilities directly into existing applications or systems. It empowers users by providing them with data insights within their familiar workflows, eliminating the need to switch between multiple tools or interfaces. By embedding analytics into applications, businesses can enable users to explore data, generate visualizations, and derive insights seamlessly, without requiring specialized data analysis skills.

One of the significant advantages of embedded analytics is its ability to offer real-time insights to users, allowing them to make timely decisions. By embedding analytics into applications such as CRM systems, project management tools, or marketing platforms, organizations can empower their employees with data-driven decision-making capabilities, enhancing productivity and efficiency.

Additionally, embedded analytics enables businesses to provide a consistent user experience by maintaining a unified interface across different functionalities. This approach fosters user adoption and minimizes the learning curve associated with using separate analytics tools.

AI-Powered Data Apps (200 words):
AI-powered data apps take data analysis a step further by incorporating artificial intelligence and machine learning algorithms into the process. These apps leverage AI technologies to automate data discovery, predictive modeling, and advanced analytics tasks, enabling users to uncover deep insights and patterns in their data.

Unlike embedded analytics, AI-powered data apps often operate as standalone applications specifically designed for data analysis and insights generation. They leverage machine learning algorithms to automate complex analytical tasks, such as anomaly detection, natural language processing, and predictive modeling. This advanced level of automation reduces the dependency on manual data processing, saving time and effort for users.

AI-powered data apps excel in handling massive volumes of data and delivering predictive insights. With the ability to process and analyze large datasets, these apps can identify trends, make accurate predictions, and provide actionable recommendations, empowering businesses to make data-driven decisions with confidence.

Conclusion (100 words):
Embedded analytics and AI-powered data apps offer distinct approaches to data analysis, each with its own set of advantages. Embedded analytics seamlessly integrates data insights into existing applications, fostering a unified user experience and enabling real-time decision-making. On the other hand, AI-powered data apps leverage advanced machine learning algorithms to automate complex analytical tasks, providing deep insights and predictive capabilities.

The choice between embedded analytics and AI-powered data apps depends on various factors, including the specific business needs, data complexity, and the desired level of automation. Ultimately, both approaches have the potential to transform organizations into data-driven enterprises, unlocking the power of data for informed decision-making and gaining a competitive edge in today's data-centric landscape.`;

      //const prompt = "Convert this blog into 10 tweets (no hashtags) that sound like an expert human and use substance not fluff, do not use hashtags, and mention our name Inventive and link to our websit madeinventive.com. Our company name is Inventive and website is: madeinventive.com" + scribeBlogPost1;
      const prompt = "Convert this blog into 10 tweets (no hashtags) that sound like an expert human and use substance not fluff, do not use hashtags, go into more detail than a basic 1 line tweet, include the customer quotes, and our company name is Scribe and website is scribehow.com" + scribeBlogPost1;
      const messages = [
        { role: "system", content: prompt },
      ];
  
      const chatCompletion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages,
        max_tokens: 25 * 20, // Generate 25 tweets (assuming an average length of 10 tokens per tweet)
      });
  
      const response = chatCompletion.data.choices
        .map((choice) => choice.message.content)
        .join("\n");
  
      console.log(response);
      console.log('done');
      }

    //apiContent();
    //hello();
    //say5funnythings();
    //rewriteContentAsTweets();
    convertDescriptionToTopics();

    return (
    <div>apiTests</div>
  )
}
