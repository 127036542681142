import React, {useState, useEffect} from "react"
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from './graphql/mutations';

import { createUserAccounts, updateUserAccounts, deleteUserAccounts } from './graphql/mutations';
import { listUserAccounts, getUserAccounts } from "./graphql/queries";

import CryptoJS from "crypto-js";


var globalAWSuserobject;
var this_user_object;
var this_user_id;

async function fetchCurrentAWSUserOnLoad() {
  try {
    globalAWSuserobject = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    return globalAWSuserobject;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

/* 6-24 added pagination b/c result wasn't returning from DB as written BUT messed up other parts! */
export async function fetchCurrentDBUserOnLoad(email) {
  try {
    let nextToken = null;
    let fetchedUserAccounts = [];

    do {
      const variables = {
        filter: {
          accountEmail: {
            eq: email
          }
        },
        limit: 100, // Number of items to fetch per request
        nextToken // Pagination token
      };

      const apiData = await API.graphql({ query: listUserAccounts, variables });
      const { items, nextToken: newNextToken } = apiData.data.listUserAccounts;

      fetchedUserAccounts = [...fetchedUserAccounts, ...items];
      nextToken = newNextToken;
    } while (nextToken);

    if (fetchedUserAccounts.length > 0) {
      const this_user_object = fetchedUserAccounts[0];
      //console.log(this_user_object);
      if(this_user_object!=undefined){
      localStorage.setItem("user_exists", 'true');        
      sessionStorage.setItem("user_exists", 'true');       
      localStorage.setItem("this_user_id", fetchedUserAccounts[0].id);
      sessionStorage.setItem("this_user_id", fetchedUserAccounts[0].id);
      localStorage.setItem("accountEmail", fetchedUserAccounts[0].accountEmail);
      sessionStorage.setItem("accountEmail", fetchedUserAccounts[0].accountEmail);
      localStorage.setItem("twitterUsername", fetchedUserAccounts[0].twitterUsername);
      sessionStorage.setItem("twitterUsername", fetchedUserAccounts[0].twitterUsername);
        //console.log(localStorage.getItem("twitterUsername"));

      localStorage.setItem("numeric_id", fetchedUserAccounts[0].numeric_id);
      sessionStorage.setItem("numeric_id", fetchedUserAccounts[0].numeric_id);
      localStorage.setItem("random_id", fetchedUserAccounts[0].random_id);
      sessionStorage.setItem("random_id", fetchedUserAccounts[0].random_id);
      localStorage.setItem("autopilotEnabled", fetchedUserAccounts[0].autopilotmode);
      sessionStorage.setItem("autopilotEnabled", fetchedUserAccounts[0].autopilotmode);

      //Update the business profile information
      localStorage.setItem("entityname", fetchedUserAccounts[0].entityname);
      sessionStorage.setItem("entityname", fetchedUserAccounts[0].entityname);
      localStorage.setItem("entitydescription", fetchedUserAccounts[0].entitydescription);
      sessionStorage.setItem("entitydescription", fetchedUserAccounts[0].entitydescription);
      localStorage.setItem("entitywebsite", fetchedUserAccounts[0].entitywebsite);
      sessionStorage.setItem("entitywebsite", fetchedUserAccounts[0].entitywebsite);
      localStorage.setItem("entitytopics", fetchedUserAccounts[0].entitytopics);
      sessionStorage.setItem("entitytopics", fetchedUserAccounts[0].entitytopics);
      localStorage.setItem("entitygoal", fetchedUserAccounts[0].preferenceGoals);
      sessionStorage.setItem("entitygoal", fetchedUserAccounts[0].preferenceGoals);
        

        //console.log("hello"+fetchedUserAccounts[0].twitterTokenSecure);
        localStorage.setItem("twitterTokenSecure", fetchedUserAccounts[0].twitterTokenSecure); 
        localStorage.setItem("twitterTokenSecretSecure", fetchedUserAccounts[0].twitterTokenSecretSecure); 
      
      
      //TODO write logic for if the user is indeed empty - hasn't been added to the DB - why?
        /*
      if(
        fetchedUserAccounts[0].twitterAccessToken!=''){
      localStorage.setItem("twitterAccessToken", fetchedUserAccounts[0].twitterAccessToken); 
      }
      if(fetchedUserAccounts[0].twitterAccessToken!=''){
      localStorage.setItem("twitterSecretAccessToken", fetchedUserAccounts[0].twitterSecretAccessToken); 
      }    
        */   
      /*
        if(
          fetchedUserAccounts[0].twitterTokenSecure!=''){
        localStorage.setItem("twitterTokenSecure", fetchedUserAccounts[0].twitterTokenSecure); 
        }
        if(fetchedUserAccounts[0].twitterTokenSecretSecure!=''){
        localStorage.setItem("twitterTokenSecretSecure", fetchedUserAccounts[0].twitterTokenSecretSecure); 
      }
      */

    }
    }
  } catch (error) {
    console.error(error);
    // Handle the error
  }
}

  
  /* 6-13 experimenting with commenting this out to fix double user creation 
  (async () => {
    try {
      await fetchCurrentAWSUserOnLoad();
      await fetchCurrentDBUserOnLoad(globalAWSuserobject.attributes.email);
    } catch (error) {
      console.log(error);
    }
  })();
  */

export async function editUserWithTwitterTokens(user_id, user_email, screen_name, token, secret){
    
    var tempTwitterUsername = screen_name;
    if(tempTwitterUsername==null){
        tempTwitterUsername = localStorage.getItem("twitter_username");
    }
    if(tempTwitterUsername==null){
        tempTwitterUsername = 'unknown';
    }

    var tokenSecure = CryptoJS.AES.encrypt(token, 'PM1989ss2023XJyS').toString();
    var tokenSecretSecure = CryptoJS.AES.encrypt(secret, 'PM1989ss2023XJyS').toString();
    /*
https://www.npmjs.com/package/crypto-js    

var CryptoJS = require("crypto-js");

// Encrypt
var ciphertext = CryptoJS.AES.encrypt('my message', 'secret key 123').toString();

// Decrypt
var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
var originalText = bytes.toString(CryptoJS.enc.Utf8);

console.log(originalText); // 'my message'
*/

    try {
      const input = {
        id: user_id,
        accountEmail: user_email,
        //twitterAccessToken: token,
        //twitterSecretAccessToken: secret,
        twitterUsername: tempTwitterUsername, 
        twitterTokenSecure: tokenSecure,
        twitterTokenSecretSecure: tokenSecretSecure
      };
  
      const updatedUser = await API.graphql(graphqlOperation(updateUserAccounts, { input }));
  
      console.log('User record updated:', updatedUser.data.updateUserAccounts);
    } catch (error) {
      console.error('Error updating user record:', error);
    }
}

export async function addUser() {
    try {
      const user = await fetchCurrentAWSUserOnLoad();
      
      checkIfThisUserExists(user.attributes.email).then(async (userExists) => {
        if (userExists) {
          //console.log('User ' + user.attributes.email +' already exists');
        } else {
          //console.log('User ' + user.attributes.email + ' does not exist');
          //Perform actions to add them to DB if the user does not exist
          const newUserData = {
            "numeric_id": generateRandomNumber(),
            "random_id": generateRandomAlphanumeric(),
            "accountEmail": user.attributes.email,
            "paid_ever": false,
            "paid_active": false,
            "subscription_type": "free",
            "signup_time": generateUnixTimeNow(),
            "signup_date": generateTodayDate(),
            "signup_source": "unknown source",
            "attributes_email": user.attributes.email,
            "attributes_email_verified": user.attributes.email_verified,
            "attributes_sub": user.attributes.sub,
            "clientEndpoint": user.client.endpoint,
            "keyPrefix": user.keyPrefix,
            "cognitoClientId": user.pool.clientId,
            "cognitoUserPoolId": user.pool.userPoolId,
            "cognitoUserDataKey": user.userDataKey,
            "cognitoUsername": user.username,
            "twitterEmail": "unknown",
            "twitterUsername": "unknown",
            "twitterAccessToken": "unknown",
            "twitterSecretAccessToken": "unknown",
            "twitterTokenSecure": "unknown",
            "twitterTokenSecretSecure": "unknown",
            "twitterDateAuthorized": "unknown",
            "autopilotmode": "false"
          };
  
          try {
            await API.graphql({
              query: createUserAccounts,
              variables: { input: newUserData },
            }).then((response) => {
              const newUser = response.data.createUserAccounts;
              localStorage.setItem('added_user', 'true');
              sessionStorage.setItem('added_user', 'true');
              // Added 6-20 so the right variables would be available asap
              fetchCurrentDBUserOnLoad(user.attributes.email);
              // console.log("Success: User account created successfully", newUser);
            });
          } catch (error) {
            // Handle any error that occurs during the GraphQL mutation
            console.log("Error: User account creation failed", error);
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

//Function: Gets all users; not in use.
  export async function fetchUsers() {
    const apiData = await API.graphql({ query: listUserAccounts });
    const fetchedUserAccounts = apiData.data.listUserAccounts.items;
    //console.log(fetchedUserAccounts);
  }    

//Function: Passes Cognito user email to Data Manager to check if they're in DB
export async function checkIfThisUserExists(AWSuseremail) {
const variables = {
    filter: {
        accountEmail: {
          eq: AWSuseremail
        }
      },
      //limit: 10, // Example: Set the desired limit
      //nextToken: null // 
  };    
    const apiData = await API.graphql({ query: listUserAccounts, variables: variables });
    const fetchedUserAccounts = apiData.data.listUserAccounts.items;
    if(fetchedUserAccounts.length===0){
        return false;

    }else{
        return true;
    }
  }      

export function generateRandomNumber(){
var randomNumber = Math.floor(Math.random() * 9000000000) + 1000000000;
return randomNumber.toString();
}

export function generateRandomAlphanumeric() {
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
let result = '';
for (let i = 0; i < 10; i++) {
  const randomIndex = Math.floor(Math.random() * characters.length);
  result += characters.charAt(randomIndex);
}
return result;
}

export function generateTodayDate() {
const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
return `${year}-${month}-${day}`;
}  

export function generateUnixTimeNow(){
return (Math.floor(Date.now() / 1000)).toString();
}

var AWSuserobject;

//Function: Uses AWS Cognito to get the current user object if they exist
export function fetchCurrentAWSUser(){
    var active_user = false;
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: true
      })
        .then((user) => {
          active_user = true;
          AWSuserobject=user;
          //console.log(user);
          resolve(user);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
}
  

  /* Start DataHelper*/
export default function DataHelper() {

/* 6-17-23 Experimenting with removing this 
async function addTestUser() {
    const testUserData = {
            "numeric_id": generateRandomNumber(),
            "random_id": generateRandomAlphanumeric(),
            "accountEmail": "testemail",
            "paid_ever": true,
            "paid_active": false,
            "subscription_type": "premium",
            "signup_time": generateUnixTimeNow(),
            "signup_date": "2023-06-10",
            "signup_source": "website",
            "attributes_email": "test@example.com",
            "attributes_email_verified": "true",
            "attributes_sub": "1234567890",
            "clientEndpoint": "https://example.com",
            "keyPrefix": "prefix_",
            "cognitoClientId": "client123",
            "cognitoUserPoolId": "pool123",
            "cognitoUserDataKey": "data_key",
            "cognitoUsername": "user123",
            "twitterEmail": "test@example.com",
            "twitterUsername": "test_user",
            "twitterAccessToken": "token123",
            "twitterSecretAccessToken": "secret_token",
            "twitterDateAuthorized": generateUnixTimeNow()
    }   
try {
    const newTestUser = await API.graphql({
      query: createUserAccounts,
      variables: { input: testUserData },
    });
    console.log("Success: User account created successfully", newTestUser);
  } catch (error) {
    console.error("Error: Failed to create user account", error);
  }
      }
*/

/*  6-17-23 Experimenting with removing this links; would be in parens in return otherwise 
   <div>DataHelper
         <button onClick={fetchUsers}>Fetch Users</button>
        <button onClick={addTestUser}>Add Test User</button>
        <button onClick={fetchCurrentAWSUser}>Fetch AWS User</button>
        <button onClick={addUser}>Add New AWS User</button>
        <button onClick={() => checkIfThisUserExists("testemail")}>Check User</button>
    </div> */
    return 
    }
