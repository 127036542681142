import React from 'react';
import axios from  'axios';

/*Posts a precanned tweet using pre-existing credentials but will want this to be dynamic */

/* 6-13-23 commenting out b/c was just for a forced tweet with prepared content
export async function makeClientRequest(){
    const requestUrl = 'https://m1efhzlwu0.execute-api.us-east-2.amazonaws.com/staging/systemapi/clientrequest';
    axios.get(requestUrl).then(response => {
    console.log(response.data);
    })
    .catch(error => {
      console.log(requestUrl);
      console.error(error);
      // Handle the error
    });
  }
  */

  //export async function makeClientPostRequest(tweetText, token, secret){
    export async function makeClientPostRequest(tweetText, tokenSecure, secretSecure){
    const requestUrl = 'https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main/systemapi/tweetrequest';
    //axios.post(requestUrl, { tweetText, token, secret }).then(response => {
      axios.post(requestUrl, { tweetText, tokenSecure, secretSecure }).then(response => {
    console.log(response.data);
    })
    .catch(error => {
      console.log(requestUrl);
      console.error(error);
      // Handle the error
    });
  }

export default function MakeRequest() {
    return;
}
