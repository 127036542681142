import React from 'react';
import './main.css'; // Import the CSS file
import logo from '../media/postmatica-logo-2.png'


export default function Home() {
  return (
    <div className='pageContainer'>
    <div>
      <center>
      <img src={logo} alt="Logo" className='logoStyling' />
      <h1><b>Postmatica</b></h1>
      <h2>Your AI Social Media Manager</h2>
      <br />
      <button className="primaryButton homepageButton" onClick={() => {window.location.href = '/signup';}}>Get Started</button>
      </center>
    </div>
    </div>
  )
}
