import React from 'react';
import AppAuth from '../AppAuth.js'
  
const Signup = () => {  
  localStorage.clear();
  sessionStorage.clear();

  return (
    <div className="pageContainer">
      <AppAuth />
    </div>
  );
};
  
export default Signup;