//import React, { useEffect } from "react";
import axios from 'axios';
import loader from './media/Infinity-1s-200px.gif';
//import {DataHelper, editUserWithTwitterTokens, forceeditUserWithTwitterTokens} from './DataHelper'
import {editUserWithTwitterTokens} from './DataHelper'

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const oauth_token = urlParams.get('oauth_token');
const oauth_verifier = urlParams.get('oauth_verifier');
//Should this instead be oauth_token_secret?
const oauth_token_secret = localStorage.getItem("auth_user_token_secret");
const session_user_id = sessionStorage.getItem("this_user_id");
const local_user_id = localStorage.getItem("this_user_id");
const session_user_email = sessionStorage.getItem("session_user_email");
const local_user_email = localStorage.getItem("local_user_email");
const local_user_email_forced = sessionStorage.getItem("local_user_email_forced");

var twitterAccessToken;
var twitterAccessSecret;
var screen_name;

/*
async function getTokensFunction(){
  //TODO add error handling for 401, 403, 502 - signal when the auth did work, and then when it didn't reprompt them or somehow solve/retry auto
  axios.get('https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main/systemapi/getTokens?oauth_verifier='+oauth_verifier+'&oauth_token='+oauth_token).then(response => {
  console.log(response.data);
  const inputString = response.data;
    
    const keyValuePairs = inputString.split('&'); // Split the string into key-value pairs
    const result = {};

  keyValuePairs.forEach(pair => {
  const [key, value] = pair.split('='); // Split each pair into key and value
  result[key] = value; // Assign the key-value pair to the result object
  });

  sessionStorage.setItem("auth_user_oauth_token", result.oauth_token);
  sessionStorage.setItem("auth_user_token_secret", result.oauth_token_secret);
  sessionStorage.setItem("auth_user_user_id", result.user_id);
  sessionStorage.setItem("auth_user_screen_name", result.screen_name);
  sessionStorage.setItem("twitterUsername", result.screen_name);
  sessionStorage.setItem("auth_user_state", "true");
  sessionStorage.setItem("auth_user_oauth_verifier", oauth_verifier);

  localStorage.setItem("auth_user_oauth_token", result.oauth_token);
    localStorage.setItem("auth_user_token_secret", result.oauth_token_secret);
    localStorage.setItem("auth_user_user_id", result.user_id);
    localStorage.setItem("auth_user_screen_name", result.screen_name);
    localStorage.setItem("twitterUsername", result.screen_name);
    localStorage.setItem("auth_user_state", "true");
    localStorage.setItem("auth_user_oauth_verifier", oauth_verifier);
    
    localStorage.setItem("just_started_authenticated", "true");

    //console.log(result);

  })
  .catch(error => {
    //localStorage.setItem("just_authenticated", "failed");
    console.error("Get tokens error "+error);
    // Handle the error

  });
}
*/
async function getTokensFunction() {
  const retryLimit = 3; // Maximum number of retries
  let retries = 0;

  const local_user_id = localStorage.getItem("this_user_id");
  const local_user_email = localStorage.getItem("local_user_email");

  const makeRequest = () => {
    axios
      .get('https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main/systemapi/getTokens?oauth_verifier=' + oauth_verifier + '&oauth_token=' + oauth_token)
      .then(response => {
  console.log(response.data);
        const inputString = response.data;
        const keyValuePairs = inputString.split('&');
        const result = {};

        keyValuePairs.forEach(pair => {
          const [key, value] = pair.split('=');
          result[key] = value;
        });

        sessionStorage.setItem("auth_user_oauth_token", result.oauth_token);
        sessionStorage.setItem("auth_user_token_secret", result.oauth_token_secret);
        sessionStorage.setItem("auth_user_user_id", result.user_id);
        sessionStorage.setItem("auth_user_screen_name", result.screen_name);
        sessionStorage.setItem("twitterUsername", result.screen_name);
        sessionStorage.setItem("auth_user_state", "true");
        sessionStorage.setItem("auth_user_oauth_verifier", oauth_verifier);

        localStorage.setItem("auth_user_oauth_token", result.oauth_token);
        localStorage.setItem("auth_user_token_secret", result.oauth_token_secret);
        localStorage.setItem("auth_user_user_id", result.user_id);
        localStorage.setItem("auth_user_screen_name", result.screen_name);
        localStorage.setItem("twitterUsername", result.screen_name);
        localStorage.setItem("auth_user_state", "true");
        localStorage.setItem("auth_user_oauth_verifier", oauth_verifier);
        localStorage.setItem("just_started_authenticated", "true");

        console.log(local_user_id+local_user_email+result.screen_name+result.oauth_token+result.oauth_token_secret);
        editUserWithTwitterTokens(local_user_id, local_user_email, result.screen_name, result.oauth_token, result.oauth_token_secret);
  })
  .catch(error => {
        if (retries < retryLimit) {
          console.log("Retrying request...");
          retries++;
          makeRequest(); // Retry the request
        } else {
          console.error("Get tokens error: " + error);
    // Handle the error
        }
  });
  };

  makeRequest();
}


//Trying retries 6-18
async function getClientLogin(retryCount = 0) {
  const local_user_id = localStorage.getItem("this_user_id");
  const new_local_user_email = localStorage.getItem("local_user_email");
  const session_user_id = sessionStorage.getItem("this_user_id");
  const new_session_user_email = sessionStorage.getItem("local_user_email");
  const loginUrl = 'https://sqhtpm45i9.execute-api.us-east-2.amazonaws.com/main/systemapi/clientlogin?oauth_verifier=' + oauth_verifier + '&oauth_token=' + oauth_token + '&oauth_token_secret=' + oauth_token_secret;
  
  try {
    const response = await axios.get(loginUrl);
    twitterAccessToken = response.data.accessToken;
    twitterAccessSecret = response.data.accessSecret;
    localStorage.setItem("just_authenticated", "true");
    screen_name = localStorage.getItem("auth_user_screen_name");
    editUserWithTwitterTokens(local_user_id, new_local_user_email, screen_name, twitterAccessToken, twitterAccessSecret);
    console.log('this id:' + local_user_id + 'email' + new_local_user_email);
    console.log(response.data);

  } catch (error) {
    console.log(loginUrl);
    console.error(error);
    // Handle the error
    if (retryCount < 3) {
      console.log("Retrying...");
      const retryDelay = 1000; // Delay in milliseconds before retrying
      await new Promise(resolve => setTimeout(resolve, retryDelay));
      getClientLogin(retryCount + 1); // Retry the function with an incremented retry count
    }
  }
}


export default function TwitterCallback() {    
 getTokensFunction().then(() => {
  setTimeout(() => {
    window.location.href = '/dashboard';
  }, 2000);
});
 //Updated 6-18 to ensure there's enough time for the call to be executed before forwarding; still need to do retries on a 403
 /*
 getClientLogin().then(() => {
  setTimeout(() => {
    //window.location.href = '/dashboard';
  }, 2000);
});
*/

 /*
 //TODO 6-17-23 Q: Should this happen as part of the getClient login function instead since it's second?
 setTimeout(() => {
  //window.location.href = '/dashboard';
}, 2000);
*/
// window.location.href = "/dashboard";
  return (
    <div>
      <center>
    <span><br /> <img src={loader} /><br /><i>&#x1F916; &#x1FAE1; &#x1F440; Connecting to your Twitter account...</i></span>
    </center>
    </div>
    
  )
}