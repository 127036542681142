import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home.js";
import Terms from "./pages/Terms.js";
import Privacy from "./pages/Privacy.js";
import TwitterCallback from "./TwitterCallback.js";
import PaymentSuccess from  "./pages/PaymentSuccess.js";
import PaymentCancellation from  "./pages/PaymentCancellation.js";
import CancellationSuccess from "./pages/CancellationSuccess.js";
import CancellationError from "./pages/CancellationError.js";
import Signup from "./pages/Signup.js";
import APITests from "./APITests.js";
import App from "./App.js";
import './index.css';

import DataHelper from "./DataHelper.js";


function PublicSite() {
    return (
       <>
         <div className="container">
           <Router>
           <Routes>
             <Route path="/" element={<Home />} />
             <Route path="/dashboard" element={<App />} />
             <Route path="/signup" element={<Signup />} />
             <Route path="/terms" element={<Terms />} />
             <Route path="/twittercallback" element={<TwitterCallback />} />  
             <Route path="/privacy" element={<Privacy />} />
             <Route path="/datahelper" element={<DataHelper />} />
             <Route path="/payment-success" element={<PaymentSuccess />} />
             <Route path="/payment-cancellation" element={<PaymentCancellation />} />
             <Route path="/cancellation-success" element={<CancellationSuccess />} />
             <Route path="/cancellation-error" element={<CancellationError />} />
             <Route path="/testapi" element={<APITests />} />
           </Routes>
           </Router>
         </div>
         
       </>
     );
   }
   
   export default PublicSite;