import { Auth } from 'aws-amplify';
import { API } from "aws-amplify";
import {DataHelper, editUserWithTwitterTokens, forceeditUserWithTwitterTokens} from './DataHelper';
import { withAuthenticator } from "@aws-amplify/ui-react";
import {fetchCurrentDBUserOnLoad, addUser} from './DataHelper'


function AppAuth({ signOut }) {

var active_user = false;  
console.log(active_user);
// Check if user exists in the AWS Amplify users table; add them if not, set some session variables; will need that user ID later + to fetch data on page
Auth.currentAuthenticatedUser({
  bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
})
  .then((user) => {
    localStorage.setItem("local_user_email", user.attributes.email);
    sessionStorage.setItem("local_user_email", user.attributes.email);
    localStorage.setItem("justLoggedIn", "true");
    
    //localStorage.setItem("numeric_id", user.attributes.email);
    //sessionStorage.setItem("local_user_email", user.attributes.email);
    //localStorage.setItem("justLoggedIn", "true");
    //fetchCurrentDBUserOnLoad(localStorage.getItem("local_user_email"));
    window.location.href = "/dashboard";
    //window.location.href = "/dashboard?refresh="
    active_user = true;
    console.log(active_user);})
  .catch((err) => console.log(err));

  return (
    <div className="pageContainer">
    </div>
    )
}

export default withAuthenticator(AppAuth);
